import axios from 'axios'
import router from '../router'
// import { Message } from 'element-ui'

axios.defaults.withCredentials=true
axios.defaults.crossDomain=true
axios.defaults.headers.post['Content-Type'] = 'application/json'

// 根据 VUE_APP_BASE_URL 生成 baseUrl
let baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://localhost:8000/1'
let staticUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL.slice(0, process.env.VUE_APP_BASE_URL.length - 2) : 'https://test-smh.panggame.com/'
if (!baseUrl.match(/^https?/i)) {
  baseUrl = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_BASE_URL}`
}

// 如果 Docker 中设置了 CRAWLAB_API_ADDRESS 这个环境变量，则会将 baseUrl 覆盖
const CRAWLAB_API_ADDRESS = '###CRAWLAB_API_ADDRESS###'
if (!CRAWLAB_API_ADDRESS.match('CRAWLAB_API_ADDRESS')) {
  baseUrl = CRAWLAB_API_ADDRESS
}

function showMessage(_this, message, messageType='error') {
  _this.screenState.messageType = messageType
  _this.screenState.message = message
  _this.screenState.messageShow = true
  setTimeout(function() {
    _this.screenState.messageShow = false
  }, 3000)
}

const request = (method, path, params, data, _this, others = {}) => {
  const url = baseUrl + path
  const headers = {
    'Authorization': 'Bearer ' + window.localStorage.getItem('token')
  }
  return axios({
    method,
    url,
    params,
    data,
    headers,
    ...others
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  }).catch((e) => {
    let response = e.response
    if (!response) {
      return e
    }
    if (response.status === 400 && _this) {
      showMessage(_this, response.data.detail)
    }
    if (response.status === 401 && router.currentRoute.path !== '/login') {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user_info')
      router.push('/account/login')
    }
    if (response.status === 500 && _this) {
      showMessage(_this, response.data.detail)
    }
    return response
  })
}

const get = (path, params) => {
  return request('GET', path, params, null)
}

const post = (path, data) => {
  return request('POST', path, {}, data, null)
}

const put = (path, data) => {
  return request('PATCH', path, {}, data, null)
}

const del = (path) => {
  return request('PATCH', path, {}, {status: 0}, null)
}

export default {
  baseUrl,
  staticUrl,
  showMessage,
  request,
  get,
  post,
  put,
  delete: del
}
