<template>
  <div class="footer" :class="isHome || screenState.hideBreadcrumb?'is-home':'not-home'">
    <div class="contact">
      {{ $router.lang.get("例行維護時間：每週四 10:00~13:00") }}<br v-if="isCollapse"/><span v-else>丨</span>
      {{ $router.lang.get("客服服務時間：週一至週五 10:00~19:00") }}<br v-if="isCollapse"/><span v-else>丨</span>
      {{ $router.lang.get("警察公函的往來：pangtwmhcs@panggame.com") }}
    </div>
    <div class="footer-logo">
		<a href="https://www.facebook.com/smhtw2020?utm_source=footer" target="_blank"><img src="../../../assets/images/ico_foot_fb.png"/></a>
		<a href="https://discord.com/invite/Pqkh8X9" target="_blank"><img src="../../../assets/images/ico_foot_dis.png"/></a>
		<a href="https://forum.gamer.com.tw/A.php?bsn=38561" target="_blank"><img src="../../../assets/images/ico_foot_bh.png"/></a>
	</div>
	<div class="_12-plus" :class="isCollapse?'_12-plus-collapse':''">
      <div>
        <img
          class="icon-12-plus"
          src="../../../assets/images/img_rating_12.jpg"
        />
      </div>
      <div>
        <div>
          {{ $router.lang.get("本遊戲依據遊戲軟體分級管理辦法分類為輔12級。" ) }}<br v-if="isCollapse"/>{{ $router.lang.get("您需年滿12歲始得使用本遊戲。" ) }}
        </div>
        <div>
          {{ $router.lang.get("本遊戲部分內容需要另支付費用。" ) }}<br v-if="isCollapse"/>{{ $router.lang.get("請註意遊戲時間，切勿沉迷或不當模仿。") }}
        </div>
      </div>
    </div>
    <div class="copyright" :class="isCollapse?'copyright-collapse':''">
      <div>
        <img
          class="pangsky-logo"
          src="../../../assets/images/img_logo_pangsky.png"
        />
        <span
          >Copyright ©
          <a href="https://pangsky.net" target="_blank">PangSky Corp.</a> All
          rights reserved.</span
        >
      </div>
      <div>
        <img
          class="supergenes-logo"
          src="../../../assets/images/img_logo_supergenes.png"
        />
        <span>Copyright © Supergenes Corp. All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useScreenState } from "../../../store";

export default {
  name: "AppFooter",
  data() {
    return {
      screenState: useScreenState(),
    };
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
    isHome () {
      return this.isCollapse || this.$route.path == '/home'
    },
  },
};
</script>

<style scoped>
.footer {
  height: 445px;
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 300;
  background-color: #10100f;
  padding-bottom: 30px;
}

.footer-logo{
	text-align: center;
	margin: 30px auto !important;
}
.footer-logo a{
	display: inline-block;
	margin: 0 30px;
}
@media only screen and (max-width: 900px){
	.footer-logo{
		margin: 10px auto !important;
	}
	.footer-logo a{
		margin: 0 10px;
	}
}
.not-home {
    background-image: url('~@/assets/images/bg_neirong_foot.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.is-home {
  height: 366px;
}

.footer div {
  margin: auto;
  max-width: 1200px;
  width: 90vw;
}

.footer > .contact {
  color: #cccccc;
  padding-top: 179px;
  text-align: center;
}

.is-home > .contact {
  padding-top: 20px;
}

.footer > ._12-plus {
  margin-top: 46px;
  display: flex;
  max-width: 582px;
  width: 90vw;
}

.footer > ._12-plus-collapse {
  margin-top: 20px;
  flex-direction: column;
  font-size: calc(0.2vw + 12.2px);
}

.footer > ._12-plus-collapse div {
  width: 100%;
  text-align: center;
}

.footer > ._12-plus-collapse img {
  margin-bottom: 6px;
}

.footer > .copyright {
  /* padding-top: 76px; */
  max-width: 440px;
  width: 90vw;
}

.footer > .copyright-collapse {
  padding-top: 36px;
}

.footer > .copyright > div {
  margin-top: 13px;
}

.copyright a {
  color: #aaaaaa;
  text-decoration: none;
}

.copyright a:hover {
  color: #cccccc;
  text-decoration: underline;
}

.icon-12-plus {
  width: 2vw;
  height: 2vw;
  margin-right: 12px;
  margin-bottom: -7px;
}

@media screen and (min-width: 769px) and (max-width: 1366px) {
  .icon-12-plus {
    width: 3vw;
    height: 3vw;
  }
}

@media screen and (max-width: 768px) {
  .icon-12-plus {
    width: 30px;
    height: 30px;
  }
}

.pangsky-logo {
  width: 116px;
  height: 21px;
  margin-right: 1.25vw;
  margin-bottom: -3px;
}
.supergenes-logo {
  width: 118px;
  height: 30px;
  margin-right: 1.25vw;
  margin-bottom: -7px;
}

</style>