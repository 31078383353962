<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { useScreenState } from "./store";
export default {
  name: "App",

  setup() {
    return { screenState: useScreenState() };
  },
  mounted() {
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenState.screenWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>

<style lang="scss">
body {
  margin: 0 auto;
  background-color: #fff;
  font-size: 16px;
  font-family: "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei",
    sans-serif;
  font-weight: 400;
  color: #ffffff;
}

button {
  outline: none;
  cursor: pointer;
  border: none;
}

.submit {
  width: 100%;
  height: 60px;
  background: #2c82ff;
  border-radius: 4px;
  font-weight: 300;
  color: #ffffff;
  font-size: 22px;
}

input[type="checkbox"] {
  width: 30px;
  height: 30px;
  border: 1px solid #2c82ff;
  border-radius: 40px;
  cursor: pointer;
}

#app {
  overflow: hidden;
}

input, select {
  background: none;
}
</style>
