
import Language from './lang'

export default new Language({
    "2GB 以上": "2GB 이상",
    "512MB 以上": "512MB 이상",
    "5GB 以上": "5GB 이상",
    "Direct X 9.0 以上": "Direct X 9.0 이상",
    "FB私訊：": "FB 비공개 메시지 :",
    "Gefrce 8600GR 以上": "Geforce 8600GR 이상",
    "Gore2 Duo Dual-core Processor 以上": "Gore2 Duo 듀얼 코어 프로세서 이상",
    "Pentium 1.4GHz 以上": "Pentium 1.4GHz 이상",
    "Radeon 9000;Geforce5200 以上": "Radeon 9000, Geforce5200 이상",
    "Windows 7/10相容": "Windows 7/10 호환",
    "windows遊戲主程式": "윈도우 게임 메인 프로그램",
    "{countDown} 秒後頁面將自動跳轉到下載專區": "{countDown} 초 후에 페이지가 자동으로 다운로드 영역으로 이동합니다.",
    "{point} 點": "{point} 포인트",
    "《個人隱私條款》": "개인 정보 보호 정책",
    "《會員服務條款》": "회원 서비스 약관",
    "一决高下": "실력을 겨루다",
    "一戰成名": "한번의 전투로 유명해지다",
    "上一頁": "이전 페이지",
    "上留言": "메시지를 남겨주세요",
    "上載圖像": "이미지 업로드",
    "上載失敗": "업로드 실패",
    "下一頁": "다음 페이지",
    "下載專區": "다운로드",
    "下載新墨魂Online": "묵혼온라인 다운로드",
    "不是該帳號綁定的信箱": "계정에 연동 된 메일이 아닙니다.",
    "不是該帳號綁定的手機號": "계정에 연동 된 전화번호가 아닙니다.",
    "主題": "제목",
    "交易號：": "거래 번호:",
    "伺服器：全服通用": "서버 : 전체서버",
    "作業系統": "운영체제",
    "使用點數": "포인트 사용",
    "例行維護時間：每週四 10:00~13:00": "정기점검 시간 : 매주 화요일 10:00 ~ 12:00",
    "例行維護時間：每週四10：~13:00": "정기점검 시간 : 매주 화요일 10:00 ~ 12:00",
    "信箱": "메일",
    "信箱地址：": "메일 주소 :",
    "信箱找回": "메일 찾기",
    "信箱格式有誤": "잘못된 메일 형식",
    "信箱註冊": "메일 회원가입",
    "信箱：": "메일:",
    "修改": "수정",
    "修改信箱地址": "메일 주소 수정",
    "修改成功": "수정성공",
    "價格": "가격",
    "價格：": "가격:",
    "儲值": "충전",
    "儲值/購買": "충전 / 구매",
    "儲值後點數：": "충전 후 포인트 :",
    "儲值點數時，請先確認金額後再購買": "포인트 충전 시 먼저 구매 금액을 확인 해주세요.",
    "儲值點數：": "저장된 가치 포인트 :",
    "儲值點數": "포인트",
    "元寶": "원보",
    "元寶是什麼？": "원보란?",
    "元寶是在新墨魂Online中使用的貨幣，可以購買遊戲內的道具。": "원보는 묵혼온라인에서 사용되는 통화이며 원보로 게임 내 아이템을 구매할 수 있습니다.",
    "全文": "전문",
    "兩次輸入的密碼並不相符，請重新輸入": "두 번 입력 한 비밀번호가 일치하지 않습니다. 다시 입력하십시오.",
    "刀": "도",
    "刀系": "도",
    "分類": "분류",
    "前往DISCORD": "DISCORD로 이동",
    "前往粉絲團": "팬페이지로 이동",
    "劍": "검",
    "劍系": "검",
    "取消": "취소 ",
    "取消購買": "구매 ",
    "只需輸入0後面數字": "0 뒤에 숫자만 입력하세요.",
    "可能被信箱過濾，請到廣告信件、垃圾信件或者相關信件歸檔中查看。": "메일에서 필터링이 될 가능성이 있으니 스팸편지함을 확인하시기 바랍니다.",
    "同意接受 ": "동의합니다. ",
    "同意接受 Panggame 消息及廣告訊息": "Panggame 뉴스 및 광고 메시지 수신에 동의",
    "同意接受在panggame提供的活動抽獎 及遊戲服務同意通知相關sms訊息": "Panggame에서 제공하는 이벤트 추첨 및 게임 서비스 수신에 동의하고 관련 SMS 메시지 알림에 동의",
    "同時使用 {field} 作為登入帳號，取消勾選可自訂登入帳號": "로그인 계정 동시사용 {field}  체크를 해제하시면 로그온 계정이 자동 저장됩니다.",
    "名譽之塔": "명예의 탑",
    "商 城": "상점",
    "商品": "상품",
    "商品：": "상품:",
    "商城": "상점",
    "商城→購買元寶": "쇼핑몰 → 원보 구매",
    "問題": "문제",
    "啟程須知": "게임정보",
    "回報": "보고",
    "回復": "댓글",
    "圖像小於5M": "5M 미만 이미지",
    "圖片分享區": "사진 공유 영역",
    "執行《新墨魂Online》系統需求": "묵혼 온라인 시스템 최소/권장사양 안내",
    "大於 5 M": "5M 이상",
    "姓名：": "이름:",
    "字數不足": "글자 수 부족",
    "字數超過": "글자 수 초과",
    "安裝/執行": "설치 / 실행",
    "安裝配備": "설치 사양",
    "完成註冊，即刻獲得上市祝福禮包！": "회원가입을 완료하고 즉시 오픈 축하 패키지를 받으십시오!",
    "官方粉絲團": "공식 커뮤니티",
    "官網首頁": "공식홈페이지 메인",
    "实体储值卡": "선불카드",
    "客服 中心": "서비스 센터",
    "客服中心": "서비스센터",
    "警察公函的往來：pangtwmhcs@panggame.com": "고객 센터 메일주소 : panggamehelp@panggame.com",
    "客服服務時間：週一至週五 10:00~19:00": "고객 서비스 시간 : 월~금 10:00~ 12:30 / 13:30~ 18:30",
    "客服服務時間：週一至週五 10：~19:00": "고객 서비스 시간 : 월~금 10:00~ 12:30 / 13:30~ 18:30",
    "客服郵箱：pangtwamhcs@panggame.com": "고객 센터 메일주소 : panggamehelp@panggame.com",
    "客服郵箱：pangtwmhcs@panggame.com": "고객 센터 메일주소 : panggamehelp@panggame.com",
    "客服香香": "고객 서비스 Xiangxiang",
    "密碼": "비밀번호",
    "密碼更改": "비밀번호 변경",
    "密碼最少 8 個字元": "비밀번호는 8 자 이상이어야합니다.",
    "密碼長度最少為 8 字元": "비밀번호는 8 자 이상이어야합니다.",
    "實名認證": "확인완료",
    "實在不行，沒關係，請聯係我們的客服。": "만약 작동되지 않는다면 걱정하지마시고 고객센터로 연락주시기 바랍니다.",
    "將會儲值 Mycard會員點數 {point}": "팡캐시 구매 후 팡캐시 {point} 충전",
    "小於 5 M": "5M 미만",
    "已付": "결제완료",
    "已發送驗證碼到 {email} 信箱": "{email} 메일로 확인 코드를 보냈습니다.",
    "已處理": "처리됨",
    "序號：": "일련 번호 :",
    "建議": "제안",
    "弓": "궁",
    "弓系": "궁",
    "強化裝備": "장비 강화",
    "待處理": "보류중",
    "復制": "복사",
    "復制券碼": "보상코드 복사",
    "復制成功": "복사성공",
    "恭喜您！成功註冊 panggame 會員": "축하합니다! Panggame 회원가입에 성공하셨습니다!",
    "您可以嘗試再次發送。": "다시 시도할 수 있습니다.",
    "您正在修改密碼, 驗證碼: {code}": "비밀번호 변경 중입니다. 인증 코드 : {code}",
    "您正在儲值點數": "귀하는 현재 캐시를 충전하고 있습니다.",
    "您正在購買元寶": "원보구매중입니다.",
    "您的信箱地址": "귀하의 메일주소",
    "您的電腦可能需要達到以下配備": "컴퓨터가 다음 사양을 만족해야 합니다.",
    "您需年滿12歲始得使用本遊戲。": "이 게임을 사용하려면 18세 이상이어야합니다.",
    "懸賞系統": "보상 시스템",
    "我的禮包": "나의 게임",
    "我的資料": "내 정보",
    "我的點数：{point}": "내 팡캐시 : {point}",
    "戰鬥技能": "무공목록",
    "手機": "휴대폰",
    "手機找回": "휴대폰으로 찾기",
    "手機號": "휴대폰번호",
    "手機號碼": "휴대폰번호",
    "手機號碼不正確，請重新輸入": "번호가 올바르지 않습니다. 다시 입력하세요.",
    "手機號碼註冊": "휴대폰 번호 등록",
    "手機號碼：": "휴대폰 번호:",
    "手機註冊": "휴대폰 회원가입",
    "手機：": "휴대폰 :",
    "扣除後點數：": "공제 후 캐시 :",
    "拳套": "권",
    "拳系": "권",
    "持有點數：": "보유 캐시 :",
    "推薦配備": "권장사양",
    "搜索": "검색",
    "支付途徑：": "결제 경로 :",
    "攻城之戰": "공성전",
    "攻略討論區": "공략게시판",
    "故事背景": "게임스토리",
    "敬請期待": "기대해주세요",
    "新信箱地址：": "새 메일주소 :",
    "新墨魂Online元寶購買": "묵혼 온라인 원보 구매",
    "新墨魂Online點數購買": "묵혼 온라인 캐시 구매",
    "新手機號碼：": "새 휴대폰 번호 :",
    "新老密碼完全一致": "이전 암호와 동일합니다.",
    "新舊信箱完全一樣": "이전 메일주소와 동일합니다.",
    "新舊手機號碼完全一樣": "이전 휴대폰번호와 동일합니다.",
    "暗器": "암기",
    "暫無數據": "데이터 없음",
    "暱稱僅有一次修改機會": "닉네임은 한 번만 수정할 수 있습니다.",
    "暱稱：": "닉네임:",
    "更多": "더보기",
    "最低配備": "최소사양",
    "最新": "최신정보",
    "最新 消息": "최근 소식",
    "最新消息": "최근소식",
    "會員 中心": "팡게임 고객센터",
    "會員 註冊": "회원가입",
    "會員中心": "팡게임 고객센터",
    "會員條款": "이용약관",
    "會員註冊": "회원가입",
    "會員資料": "마이페이지",
    "會員帳號": "회원 계정",
    "未付": "미결제",
    "未查找到該帳號": "계정을 찾을 수 없습니다",
    "本遊戲依據遊戲軟體分級管理辦法分類為輔12級。": "게임물관리위원회의 등급에 따라 본 게임은 청소년 이용불가입니다.",
    "本遊戲部分內容需要另支付費用。": "본 게임의 일부는 추가 결제가 필요합니다.",
    "查詢密碼": "비밀번호 찾기",
    "查詢帳號": "아이디 찾기",
    "槍": "창",
    "槍系": "창",
    "歡迎加入panggame!": "panggame에 가입하신 것을 환영합니다!",
    "江湖爭霸": "문파토너먼트",
    "没找到帳號": "계정을 찾을 수 없습니다.",
    "消耗點數：": "캐시소비 :",
    "消費記錄": "소비기록",
    "添加": "추가",
    "爲使用遊戲，請按照以下的格式進行註冊流程。": "게임을 이용하기 위해서는 회원가입 절차를 따라 가입을 진행해주시기 바랍니다.",
    "物品分解": "아이템 분해",
    "物品組合": "아이템 조합",
    "特色 地圖": "게임이미지",
    "特色地圖": "주요지도",
    "狀態": "상태",
    "狀態：": "상태:",
    "獲取驗證碼": "인증 코드 받기",
    "玩家必須先儲值點數，方可在 購買元寶 頁面購買新墨魂Online的元寶": "묵혼 온라인 원보를 구매하기 위해서는 먼저 팡캐시를 충전해야 합니다.",
    "玩家討論區": "자유게시판",
    "異常": "이상",
    "當前手機號碼已註冊": "현재 휴대폰 번호가 등록되었습니다.",
    "當前手機號與已有手機號衝突，請自訂帳號": "현재 휴대폰 번호가 기존의 번호와 중복됩니다. 다시 설정하세요.",
    "當前點數": "현재 팡캐시",
    "當前點數：": "현재 팡캐시 :",
    "登入": "로그인",
    "登入留言": "로그인 메시지",
    "登出": "로그아웃",
    "登出成功": "로그아웃 성공",
    "登錄日": "로그인 날짜",
    "登錄日：": "로그인 날짜 :",
    "發佈辱罵,相同文章重覆發文,誹謗,謠言等違反營運政策的貼文時，可能會受到限制發文，若有違法情事發生，自行負擔法律相關責任. {length} / 最長100個字元": "욕설, 동일 기사 반복 투고, 비방, 루머 등 운영 정책에 위배되는 게시물은 제한 될 수 있으며, 위반시 법적 책임은 귀하에게 있습니다. {길이} / 최대 100 자",
    "發文": "게시",
    "發送成功": "발송성공",
    "硬碟容量": "하드 디스크 용량",
    "確認": "확인",
    "確認密碼": "비밀번호",
    "確認新密碼不一致": "비밀번호 확인",
    "確認新密碼：": "새 비밀번호 확인 :",
    "確認購買": "구매 확인",
    "社 群": "커뮤니티",
    "社群": "커뮤니티",
    "累積點數：": "누적 캐시 :",
    "綜合": "종합",
    "網路會有一定延遲，請等幾分鐘後再去看看。": "네트워크에서 지연이있을 수 있습니다. 다시 확인하기 전에 몇 분 정도 기다려주십시오.",
    "群雄逐鹿": "群雄逐鹿",
    "聲望值 (RP)": "랭킹포인트 (RP)",
    "腥紅之月": "腥紅之月",
    "自訂帳號長度最多為 32 字元": "닉네임의 길이는 최대 32 자입니다.",
    "自訂帳號需為英文字母和數字的組合，且第一個字元必須為英文，英文及數字必須為半形": "닉네임은 영문자와 숫자의 조합이어야하며 첫 번째 문자는 영문이어야하며 영문과 숫자는 반자 여야합니다.",
    "舊信箱地址：": "이전 메일 주소 :",
    "舊手機號碼：": "이전 휴대폰번호 :",
    "血雨腥風": "혈풍시간",
    "裝備寶石": "보석강화",
    "裝備技能": "장비기능",
    "裝備親密": "장비 친밀도",
    "角色：": "캐릭터 :",
    "訂單編號": "주문번호",
    "訂單編號：": "주문번호:",
    "記住登入資訊": "로그인 정보 기억",
    "記憶卡": "메모리 카드",
    "註冊《新墨魂Online》遊戲帳號": "<묵혼 온라인> 회원가입",
    "註冊成功": "가입성공",
    "註冊會員": "회원가입",
    "1:1詢問": "1:1문의",
    "該帳號沒有綁定信箱": "계정이 메일과 연동되지 않았습니다.",
    "該帳號沒有綁定手機號": "계정이 휴대폰과 연동되어 있지 않습니다.",
    "該遊戲為PC端遊戲，前往PC端下載": "본 게임은 PC 게임입니다. PC로 이동하여 다운로드하십시오.",
    "誰與爭鋒": "누가 맞서겠느냐",
    "請勾選同意接受條款": "약관에 동의하려면 선택하십시오",
    "請注意遊戲時間，切勿沉迷或不當模仿。": "게임 이용시간에 주의하시길 바라며 절대로 게임을 복제하지 마십시오.",
    "請確認一下Email地址是否拼寫錯誤，您可以用正確的信箱地址重新註冊。": "이메일 주소의 철자가 틀렸는 지 확인하세요. 올바른 이메일 주소로 다시 등록 할 수 있습니다.",
    "請確認密碼": "비밀번호를 확인하세요.",
    "請立即前往，以便完成加入會員操作！": "지금 바로 회원 가입을 완료 해주세요!",
    "請註意遊戲時間，切勿沉迷或不當模仿。": "게임 이용시간에 주의하시길 바라며 절대로 게임을 복제하지 마십시오.",
    "請輸入": "입력하세요",
    "請輸入信箱": "메일을 입력하세요",
    "請輸入姓名": "이름을 입력하세요",
    "請輸入密碼": "비밀번호를 입력하세요",
    "請輸入手機號": "휴대폰 번호를 입력하세요",
    "請輸入手機號碼": "휴대폰 번호를 입력하세요",
    "請輸入文章主題": "게시글 제목을 입력하세요",
    "請輸入新的信箱地址": "새 메일주소를 입력하세요",
    "請輸入正確帳號": "올바른 계정을 입력하세요",
    "請輸入自訂帳號": "닉네임을 입력하세요",
    "請輸入角色": "캐릭터명을 입력하세요",
    "請輸入帳號": "계정을 입력하세요",
    "請輸入身份證號": "주민등록번호를 입력하세요",
    "請輸入郵箱": "이메일을 입력하세요",
    "請輸入驗證碼": "인증 코드를 입력하세요",
    "請點擊上方按鈕下載遊戲主程式": "상단의 버튼을 클릭하여 게임클라이언트를 다운로드하세요",
    "请上載圖像": "이미지를 업로드하세요",
    "请输入手机号，不为 0 开头的": "0으로 시작하지 않는 휴대 전화 번호를 입력하세요.",
    "帳號": "계정",
    "帳號 登入": "계정 로그인",
    "帳號第一位必須為英文字母，最多 32 個字元": "계정의 첫 번째 문자는 영문자여야 합니다(최대 32 자)",
    "帳號：": "계정:",
    "購買": "구입",
    "購買P-point後": "P-point 구매 후",
    "購買價格：": "구매가격:",
    "購買元寶": "원보 구매",
    "購買元寶時，請先確認金額後再購買。": "원보를 구매 시, 먼저 금액을 확인하십시오.",
    "購買成功": "구매성공",
    "購買記錄": "구매기록",
    "身份證號格式有誤": "주민등록번호 형식이 잘못되었습니다.",
    "輕功技能": "경공",
    "輸入新密碼：": "새 비밀번호 입력 :",
    "輸入新暱稱": "새 닉네임 입력",
    "輸入的Email已被註冊使用": "입력하신 이메일은 이미 가입된 이메일입니다.",
    "輸入舊密碼：": "이전 비밀번호 입력 :",
    "輸入鏈接": "링크 입력",
    "輸入關鍵字": "키워드 입력",
    "輸入驗證碼": "인증 코드 입력",
    "这是當前手機號碼，請重新輸入": "이미 등록된 번호입니다. 다시 입력하세요.",
    "進階攻略": "진급공략",
    "遊戲 下載": "게임 다운로드",
    "遊戲 指南": "게임 가이드",
    "遊戲 資訊": "게임 정보",
    "遊戲資訊": "게임정보",
    "遊戲下載": "게임 다운로드",
    "遊戲使用": "게임 사용",
    "遊戲問題": "게임 문제",
    "遊戲地圖": "게임 맵",
    "遊戲指南": "게임 가이드",
    "遊戲特色": "게임 특징",
    "運營": "운영",
    "道具資訊：": "아이템 정보 :",
    "邀請你來到新墨魂Online": "당신을 묵혼 온라인에 초대합니다.",
    "還沒有收到驗證信件？": "인증메일을 받지 못하셨습니까?",
    "還沒有收到驗證郵件？": "인증메일을 받지 못하셨습니까?",
    "郵箱：": "메일:",
    "重新發送": "재전송",
    "階級繼承": "장비단계전수",
    "隱私保護": "개인 정보 보호",
    "霸氣翅膀": "날개",
    "靈魂石": "룬(영혼석)",
    "顯示卡": "그래픽 카드",
    "風起雲湧": "바람이 일고 구름이 피어오르다",
    "首頁": "메인",
    "驗證碼格式有誤": "인증 코드 형식이 잘못되었습니다.",
    "驗證碼為 6 位數字": "인증 코드는 6 자리입니다.",
    "驗證碼：": "인증 코드:",
    "點擊再次發送": "다시 보내려면 클릭",
    "點擊跳轉到FB主頁": "클릭하면 FB 홈페이지로 이동합니다.",
    "點数不足": "팡캐시 부족",
    "點數是什麼？": "팡캐시는 무엇입니까?",
    "交易失敗": "거래실패",
    "密碼不能有特殊字元": "비밀번호는 특수문자를 포함할 수 없습니다.",
    "信箱已被註冊": "이미 등록된 이메일입니다.",
    "已答復的詢問不能編輯": "이미 답변된 문의는 수정할 수 없습니다.",
    "手機號已被註冊": "이미 등록된 번호입니다.",
    "支付方式不支持无面额的商品": "지원되지 않는 결제수단은 이용할 수 없습니다.",
    "新信箱的驗證碼不正確或已過期，請重新獲取驗證碼": "새로운 메일의 인증번호가 잘못되었거나 이미 유효기간이 지났습니다. 다시 인증번호를 받으시기 바랍니다.",
    "新手機號的驗證碼不正確或已過期，請重新獲取驗證碼": "새로운 휴대폰 번호의 인증번호가 잘못되었거나 이미 유효기간이 지났습니다. 다시 인증번호를 받으시기 바랍니다.",
    "登入失敗頻率過高，請稍後重試": "로그인실패 횟수가 너무 많습니다, 잠시 후 다시 시도해주시기 바랍니다.",
    "舊信箱的驗證碼不正確或已過期，請重新獲取驗證碼": "이전 이메일의 인증번호가 잘못되었거나 이미 유효기간이 지났습니다. 다시 인증번호를 받으시기 바랍니다.",
    "舊手機號的驗證碼不正確或已過期，請重新獲取驗證碼": "이전 휴대폰 번호의 인증번호가 잘못되었거나 이미 유효기간이 지났습니다. 다시 인증번호를 받으시기 바랍니다.",
    "頻率過高，請稍後重試": "실패횟수가 너무 많습니다, 잠시 후 다시 시도해주시기 바랍니다.",
    "驗證碼發送失敗，請稍後重試": "인증번호 발송 실패, 잠시 후 시도해주시기 바랍니다.",
    "驗證碼發送頻率過高，請稍後重試": "인증번호 발송 실패횟수가 너무 많습니다, 잠시 후 다시 시도해주시기 바랍니다.",
    "预约礼包": "예약패키지",
    "前往领取": "보상받기",
    "礼包码": "보상교환코드",
    "我的礼包": "내 패키지현황",
    "复制券码": "교환코드 복사",
    "复制": "복사",
    "檢舉": "신고"
  })