import globalMessage from "./globalMessage.vue";
import { h, render } from "vue";
let messageList = [];
const handleData = (type, message, _this) => {
  messageList = _this.screenState.messageList;
  messageList.push({ type, message });
  const container = document.createElement("div");
  const vm = h(globalMessage, { type, message });
  render(vm, container);
  document.body.appendChild(container);
  setTimeout(() => {
    messageList.shift()
  }, 2000);
};
class messageDemo {
  success(message, _this) {
    handleData("success", message, _this);
  }
  error(message, _this) {
    handleData("error", message, _this);
  }
}
const message = new messageDemo();
export { message, messageList };
