<template>
    <div class="comments-container">
        <div v-if="screenState.user" class="comment-editor">
            <pang-editor @input="commentInput" ref="comment" required :maxlength="500" :value="commentInputing"/>
            <div class="hint">* {{$router.lang.get('發佈辱罵,相同文章重覆發文,誹謗,謠言等違反營運政策的貼文時，可能會受到限制發文，若有違法情事發生，自行負擔法律相關責任. {length} / 最長100個字元', {length: comment.length})}}</div>
            <div class="butt-container">
                <pang-butt @click="submit" :text="$router.lang.get('上留言')" btype="submit" :style="{marginLeft: '0'}" :loading="loading"/>
            </div>
        </div>
        <div v-else class="login-class">
            <pang-butt @click="gotoLogin" :text="$router.lang.get('登入留言')" btype="submit" :style="{marginLeft: '0', maxWidth: '150px'}"/>
        </div>
        <div class="comments" v-if="comments.length > 0">
            <div class="comment-title">{{ $router.lang.get('留言') }}</div>
            <div v-for="item in comments" :key="item.id" class="comment-container">
                <div class="pubtime">
                    <span>{{ item.author_nickname?item.author_nickname:'客服香香' }}</span>
                    <span>{{ item.create_at.slice(0, 19) }}</span>
                </div>
                <div v-html="item.content"/>
                <div class="reply-container" v-if="!subCommentDisabled">
                    <pang-butt v-if="!item.commentOpen" @click="commentReply(item)" :text="$router.lang.get('回復')" btype="submit" :style="{marginLeft: '0', width: '50px', height: '25px', lineHeight: '25px'}"/>
                    <div v-else>
                        <pang-editor :ref="'comment' + item.id" @input="childInput" required :maxlength="500" :value="item.childComment"/>
                        <pang-butt @click="childSubmit(item)" :text="$router.lang.get('上留言')" btype="submit" :style="{marginLeft: '0', width: '90px'}" :loading="loading"/>
                    </div>
                    <div class="delbutt-container" v-if="screenState.user && screenState.user.id == item.author">
                        <pang-butt  btype="submit" :text="$router.lang.get('刪除')" @click="deleteComment(item)" :style="{marginRight: '0', width: '50px', height: '25px', lineHeight: '25px'}"/>
                    </div>
                </div>
                <div class="children" v-if="item.children.length > 0">
                    <div v-for="child in item.children" :key="child.id" class="child-container">
                        <div class="pubtime">
                            <span>{{ child.author_nickname?child.author_nickname:'客服香香' }}</span>
                            <span>{{ child.create_at.slice(0, 19) }}</span>
                        </div>
                        <div v-html="child.content"/>
                        <div class="delbutt-container" v-if="screenState.user && screenState.user.id == child.author">
                            <pang-butt  btype="submit" :text="$router.lang.get('刪除')" @click="deleteComment(child)" :style="{marginRight: '0', width: '50px', height: '25px', lineHeight: '25px'}"/>
                        </div>
                    </div>
                    <pang-butt v-if="item.hasMore || item.children.length == 5" @click="loadmore(item)" :text="$router.lang.get('更多')" btype="submit" :style="{marginLeft: '0', marginBottom: '30px', width: '80px'}" :loading="loading"/>
                </div>
            </div>
            <pang-pager v-if="totalPages > 1" @change="goto" :totalPages="totalPages" :currentPage="currentPage"/>
        </div>
        <pang-dialog
            v-if="deleteShowed"
            :title="$router.lang.get('刪除留言')"
            :cancelText="$router.lang.get('取消')"
            :submitText="$router.lang.get('確認')"
            :loading="loading"
            @cancel="cancel" @submit="confirm">
            <div class="dialog-content">
                {{ $router.lang.get('確定要刪除嗎？') }}
            </div>
        </pang-dialog>
    </div>
</template>

<script>
import { useScreenState } from '../store';
import PangEditor from '../components/PangEditor'
import PangPager from '../components/PangPager'
import PangButt from '../components/PangButt'
import PangDialog from '../components/PangDialog'
import request from '../api/request'
import message from '../utils/message'

export default {
    name: 'PangComment',
    components: {
        PangEditor,
        PangPager,
        PangButt,
        PangDialog,
    },
    props: {
        aritcleid: {
            type: Number,
            required: true,
        },
        subCommentDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            loading: false,
            deleteShowed: false,
            screenState: useScreenState(),
            comment: '',
            comments: [],
            currentPage: 1,
            totalPages: 1,
            commentInputing: '',
        };
    },
    methods: {
        deleteComment (item) {
            this.deleteShowed = true
            this.deletingItem = item
        },
        cancel () {
            this.deleteShowed = false
        },
        confirm () {
            this.loading = true
            let _this = this
            request.delete('/comments/' + this.deletingItem.id).then(function (res) {
                _this.loading = false
                if (res.status == 200) {
                    _this.getComments()
                    _this.deleteShowed = false
                }
                else if (res.status == 404) {
                    _this.$router.replace('/404')
                }
                else {
                    message.resMessage(res, _this)
                }
            })
        },
        getComments () {
            let _this = this
            let params = {
                article_id: this.aritcleid,
                page: this.currentPage,
                limit: 10}
            request.get('/comments', params, this).then(function (res) {
                if (res.status == 200) {
                    _this.comments = res.data.results
                    _this.totalPages = res.data.paging.total_pages
                }
                else {
                    message.resMessage(res, _this)
                }
            })
        },
        async submit () {
            if (await this.$refs.comment.validateValue(this.comment)) {
                let _this = this
                let data = {
                    article: this.aritcleid,
                    content: this.comment,
                }
                this.loading = true;
                this.commentInputing = this.comment
                request.post('/comments', data, _this).then(function (res) {
                    if (res.status == 201) {
                        console.log(res.data)
                        _this.currentPage = 1
                        _this.comment = ''
                        _this.commentInputing = ''
                        _this.getComments()
                    }
                    else {
                        message.resMessage(res, _this)
                    }
                    _this.loading = false;
                })
            }
        },
        commentInput (e) {
            this.comment = e;
        },
        gotoLogin() {
            this.$router.replace({ path: '/account/login', query: {returnurl: this.$route.fullPath}});
        },
        goto (page) {
            this.currentPage = page
            this.getComments()
        },
        commentReply (comment) {
            for (let i in this.comments) {
                this.comments[i].commentOpen = false
            }
            this.currentComment = comment
            comment.commentOpen = true
            if (comment.comment)
                comment.childComment = comment.comment
            else
                comment.childComment = ''
        },
        childInput (e) {
            this.currentComment.comment = e
        },
        loadmore (comment) {
            console.log(comment)
            let _this = this
            let params = {
                parent_id: comment.id,
                page: comment.currentPage?comment.currentPage+1:2,
            }
            this.loading = true;
            request.get('/comments', params, _this).then(function (res) {
                if (res.status == 200) {
                    console.log(res.data)
                    comment.children.push(...res.data.results)
                    comment.currentPage = res.data.paging.page
                    comment.hasMore = res.data.paging.page < res.data.paging.total_pages
                }
                else {
                    message.resMessage(res, _this)
                }
                _this.loading = false;
            })
        },
        async childSubmit (comment) {
            console.log(comment)
            if (await this.$refs['comment' + comment.id].validateValue(comment.comment)) {
                let _this = this
                let data = {
                    content: comment.comment,
                    parent: comment.id,
                }
                this.loading = true;
                request.post('/comments', data, _this).then(function (res) {
                    if (res.status == 201) {
                        comment.children.unshift(res.data)
                        comment.commentOpen = false
                        comment.comment = ''
                    }
                    else {
                        message.resMessage(res, _this)
                    }
                    _this.loading = false;
                })
            }
        },
    },
    mounted () {
        this.getComments()
    },
}
</script>

<style>
.comments > .pang-pager > div:first-child {
    margin-left: 0;
}
</style>

<style scoped>

.delbutt-container {
    width: 100%;
    margin: 10px auto;
}

.dialog-content {
    padding: 30px 0;
    font-size: 20px;
}

.comments, .comment-editor, .login-class {
    color: #686A6E;
    margin-bottom: 200px;
    max-width: 1040px;
    width: 90vw;
    margin: auto;
}

.hint {
    font-size: 14px;
}


.butt-container {
    display: flex;
    max-width: 500px;
    margin-top: 20px;
}

.butt-container > div {
    width: 180px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    cursor: pointer;
}


.submit-butt {
    background-color: #C6A86C;
}

.login-class > div{
    cursor: pointer;
    margin: 40px;
}

.comments {
    margin: 100px auto;
}

.comment-container {
    margin: 30px 0;
    padding: 30px 0;
    border-bottom: 1px solid#D6E0EB;
}

.pubtime {
    margin-bottom: 20px;
}

.pubtime > span{
    color: #A8AEB3;
    padding-right: 20px;
}

.children {
    margin: 40px 0 0 60px;
    padding: 0 40px;
    border: 1px solid#D6E0EB;
}

.reply-container {
    margin: 30px 0;
}

.child-container {
    margin: 30px 0;
}

.comment-title {
    font-size: 32px;
    border-bottom: 1px solid#D6E0EB;
    padding-bottom: 15px;
}
</style>
