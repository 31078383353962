function showMessage(_this, message, messageType = "error") {
  let msg = {
    messageType: messageType,
    message: message,
    messageShow: true,
  };
  _this.screenState.msgs.push(msg);
  setTimeout(function() {
    for (let i in _this.screenState.msgs) {
      if (_this.screenState.msgs[i].messageShow) {
        _this.screenState.msgs[i].messageShow = false;
        break;
      }
    }
  }, 2500);
  setTimeout(function() {
    _this.screenState.msgs.pop();
  }, 3000);
}
function showMessageSuccess(_this, message, messageType = "success") {
  let msg = {
    messageType: messageType,
    message: message,
    messageShow: true,
  };
  _this.screenState.msgs.push(msg);
  setTimeout(function() {
    for (let i in _this.screenState.msgs) {
      if (_this.screenState.msgs[i].messageShow) {
        _this.screenState.msgs[i].messageShow = false;
        break;
      }
    }
  }, 2500);
  setTimeout(function() {
    _this.screenState.msgs.pop();
  }, 3000);
}

function resMessage(response, _this) {
  if ((response.status === 400 || response.status === 500) && _this) {
    showMessage(_this, response.data.detail);
  } else if (
    response.status === 401 &&
    _this &&
    _this.$router.currentRoute.path !== "/login"
  ) {
    _this.$router.push("/account/login");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user_info");
  }
}

export default {
  showMessage,
  resMessage,
  showMessageSuccess,
};
