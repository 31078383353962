<template>
  <div class="pang-map">
    <div
    class="map-item"
    :class="isCollapse?(this.screenState.screenWidth < 600?'full-collapse':'collapse'):''"
    @mouseover="activate"
    @mouseleave="deactivate"
    :style="{'backgroundImage': 'url(\'' + staticUrl +cover+'\')'}">
    <div :class="always || active || isCollapse?'show':'not-show'">{{ title }}</div>
    <img v-show="(active || isCollapse) && always" src="../assets/images/play.png"/>
    </div>
  </div>
</template>

<script>
import { useScreenState } from '../store';
import request from '../api/request'

export default {
    name: 'PangMap',
    props: {
        cover: {
        type: String,
        required: true,
        },
        title: {
            type: String,
            required: true,
        },
        always: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            screenState: useScreenState(),
            active: false,
          staticUrl: request.staticUrl,
        }
    },
    computed: {
        isCollapse() {
        return this.screenState.screenWidth < 900;
        },
    },
    methods: {
        activate () {
            this.active = true
        },
        deactivate () {
            this.active = false
        },
    },
}
</script>

<style scoped>
.pang-map {
    display: inline-block;
}

.map-item {
    max-width: 243px;
    width: calc(22.5vw - 22px);
    max-height: 203px;
    height: 18.8vw;
    overflow: hidden;
    border: 1px solid #000;
    margin: 9px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
}

.map-item > img {
    width: 15%;
    display: inline-block;
    margin: auto;
    padding-top: 10px;
}

.collapse {
    max-width: inherit;
    max-height: inherit;
    width: calc(45vw - 22px);
    height: 37.6vw;
}

.full-collapse {
    max-width: inherit;
    max-height: inherit;
    width: calc(90vw - 22px);;
    height: 75.2vw;
}

.show {
    line-height: 29px;
    height: 29px;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.48);
    font-size: 14px;
    color: #CACACA;
    overflow: hidden;
}

.not-show {
    line-height: 29px;
    height: 29px;
    padding: 0 10px;
    background: none;
    font-size: 14px;
    color: rgb(0,0,0,0);
    overflow: hidden;
}

</style>
