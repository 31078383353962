<template>
  <div class="pang-input">
    <input type="file"
        @input="uploadFile"
       accept="image/png, image/jpeg">
    <div v-if="message" class="error-message">{{ message }}</div>
    <div v-else class="normal-message">* {{ $router.lang.get('小於 5 M') }}</div>
    <div class="preview">
        <img v-if="inputValue" :src="inputValue" alt=""/>
    </div>
  </div>
</template>

<script>

import request from '../api/request'
import message from '../utils/message'

export default {
  name: 'PangUpload',
  props: {
    value:  String,
  },
  setup(props, { emit }) {
    const updateValue = value => {
        emit('update:value', value)
    }
    return { updateValue }
  },
  data() {
    return {
      message: '',
      inputValue: this.value,
    }
  },
  methods: {
    validateValue (value) {
        if (value)
            return true
        this.message = this.$router.lang.get('请上載圖像')
        return false
    },
    uploadFile (e) {
        let file = e.target.files[0]
        if(file.size / 1024 /1024 >5){
            this.message = this.$router.lang.get('大於 5 M')
        }
        else {
            let form = new FormData();
            form.append('file', file);
            this.uploadingFile(form)
        }
    },
    uploadingFile(data){
        let _this = this
        fetch(request.baseUrl + '/communities/upload/',{
            method:'POST',
            body:data
        }).then(function(response) {
            return response.json();
        }).then(function(myJson) {
            if (myJson.url) {
                _this.inputValue = myJson.url
                _this.updateValue(myJson.url)
            }
            else if (myJson.detail)
                message.showMessage(_this, myJson.detail)
            else
                message.showMessage(_this, _this.$router.lang.get('上載失敗'))
        });
    },
  },
}
</script>

<style scoped>

  input {
    height: 44px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    width: calc(100% - 30px);
    padding: 0 15px;
  }

  input:focus {
    outline: none !important;
    border:1px solid #C26161;
    box-shadow: 0 0 5px #C7C7C7;
  }

  input::placeholder {
    font-weight: 300;
    color: #999999;
  }

  .error-message {
    color: #C26161;
    margin: 8px 4px 0 4px;
    height: 24px;
  }

  .preview > img {
      width: 100%;
      max-height: 300px;
  }

  .normal-message {
    color: #939393;
    margin: 8px 4px 0 4px;
    height: 24px;
  }
</style>
