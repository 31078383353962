import { createRouter, createWebHistory } from "vue-router";
import Layout from "../views/layout/Layout";
import lang from "../lang";
import Cookies from "js-cookie";

const routerHistory = createWebHistory();

export const constantRouterMap = [
  {
    path: "/",
    redirect: "/home",
    hidden: true,
  },
  {
    path: "/Member/memberAgree.aspx",
    redirect: "/account/register",
    hidden: true,
  },
  {
    path: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        component: () => import("../views/home/Home"),
        meta: {
          title: lang.get("首頁"),
        },
      },
    ],
    meta: {
      title: lang.get("首頁"),
    },
  },
  {
    path: "/recent",
    component: Layout,
    children: [
      {
        path: "/recent",
        component: () => import("../views/recent/Recent"),
        meta: {
          title: lang.get("最新消息"),
        },
        hidden: true,
      },
      {
        path: "/recent/notice",
        component: () => import("../views/recent/Recent"),
        meta: {
          title: lang.get("公告"),
        },
      },
      {
        path: "/recent/notice/:id(\\d+)",
        component: () => import("../views/recent/Detail"),
        meta: {
          title: lang.get("公告"),
        },
        hidden: true,
      },
      {
        path: "/recent/activity",
        component: () => import("../views/recent/Recent"),
        meta: {
          title: lang.get("活動"),
        },
      },
      {
        path: "/recent/activity/:id(\\d+)",
        component: () => import("../views/recent/Detail"),
        meta: {
          title: lang.get("活動"),
        },
        hidden: true,
      },
      {
        path: "/recent/update",
        component: () => import("../views/recent/Recent"),
        meta: {
          title: lang.get("更新"),
        },
      },
      {
        path: "/News/Detail.aspx",
        component: () => import("../views/recent/Redirect"),
        hidden: true,
      },
      {
        path: "/recent/update/:id(\\d+)",
        component: () => import("../views/recent/Detail"),
        meta: {
          title: lang.get("更新"),
        },
        hidden: true,
      },
    ],
    meta: {
      title: lang.get("最新消息"),
    },
  },
  {
    path: "/news",
    component: Layout,
    children: [
      {
        path: "/news",
        redirect: "/news/guide",
        hidden: true,
      },
      // {
      //   path: '/news',
      //   component: () => import('../views/news/News'),
      //   meta: {
      //     title: lang.get('遊戲資訊')
      //   },
      //   hidden: true,
      // },

      {
        path: "/news/guide",
        component: () => import("../views/news/Guide"),
        meta: {
          title: lang.get("遊戲指南"),
        },
      },
      {
        path: "/news/guide/:id(\\d+)",
        component: () => import("../views/news/Detail"),
        meta: {
          title: lang.get("遊戲指南"),
        },
        hidden: true,
      },
      {
        path: "/news/map",
        component: () => import("../views/news/Map"),
        meta: {
          title: lang.get("特色地圖"),
        },
      },
      {
        path: "/news/video",
        component: () => import("../views/news/Video"),
        meta: {
          title: lang.get("精選影片"),
        },
      },
      {
        path: "/news/map/:id(\\d+)",
        component: () => import("../views/news/Detail"),
        meta: {
          title: lang.get("特色地圖"),
        },
        hidden: true,
      },
    ],
    meta: {
      title: lang.get("遊戲資訊"),
    },
  },
  {
    path: "/community",
    component: Layout,
    children: [
      {
        path: "/community",
        redirect: "/community/gamer",
        hidden: true,
      },
      {
        path: "/community/gamer",
        component: () => import("../views/community/Community"),
        meta: {
          title: lang.get("玩家討論區"),
        },
      },
      {
        path: "/community/gamer/new",
        component: () => import("../views/community/New"),
        meta: {
          title: lang.get("玩家討論區"),
        },
        hidden: true,
      },
      {
        path: "/community/gamer/:id(\\d+)",
        component: () => import("../views/community/Detail"),
        meta: {
          title: lang.get("玩家討論區"),
        },
        hidden: true,
      },
      {
        path: "/community/strategy",
        component: () => import("../views/community/Community"),
        meta: {
          title: lang.get("攻略討論區"),
        },
      },
      {
        path: "/community/strategy/new",
        component: () => import("../views/community/New"),
        meta: {
          title: lang.get("攻略討論區"),
        },
        hidden: true,
      },
      {
        path: "/community/strategy/:id(\\d+)",
        component: () => import("../views/community/Detail"),
        meta: {
          title: lang.get("攻略討論區"),
        },
        hidden: true,
      },
      {
        path: "/community/picture",
        component: () => import("../views/community/Community"),
        meta: {
          title: lang.get("圖片分享區"),
        },
      },
      {
        path: "/community/picture/new",
        component: () => import("../views/community/New"),
        meta: {
          title: lang.get("圖片分享區"),
        },
        hidden: true,
      },
      {
        path: "/community/picture/:id(\\d+)",
        component: () => import("../views/community/Detail"),
        meta: {
          title: lang.get("圖片分享區"),
        },
        hidden: true,
      },
    ],
    meta: {
      title: lang.get("社群"),
    },
  },
  {
    path: "/customerservice",
    component: Layout,
    children: [
      {
        path: "/customerservice",
        redirect: "/customerservice/faq",
        hidden: true,
      },
      {
        path: "/customerservice/faq",
        component: () => import("../views/customerservice/Faq"),
        meta: {
          title: "FAQ",
        },
      },
      {
        path: "/customerservice/faq/:id(\\d+)",
        component: () => import("../views/customerservice/FaqDetail"),
        meta: {
          title: "FAQ",
        },
        hidden: true,
      },
      {
        path: "/customerservice/question",
        component: () => import("../views/customerservice/Question"),
        meta: {
          title: lang.get("1:1詢問"),
        },
      },
      {
        path: "/customerservice/question/:id(\\d+)",
        component: () => import("../views/customerservice/QuestionDetail"),
        meta: {
          title: lang.get("1:1詢問"),
        },
        hidden: true,
      },
      {
        path: "/customerservice/question/:id(\\d+)/edit",
        component: () => import("../views/customerservice/Edit"),
        meta: {
          title: lang.get("1:1詢問"),
        },
        hidden: true,
      },
      {
        path: "/customerservice/question/new",
        component: () => import("../views/customerservice/New"),
        meta: {
          title: lang.get("1:1詢問"),
        },
        hidden: true,
      },
      {
        path: "/customerservice/terms",
        component: () => import("../views/customerservice/Terms"),
        meta: {
          title: lang.get("會員條款"),
        },
      },
      {
        path: "/customerservice/privacy",
        component: () => import("../views/customerservice/Privacy"),
        meta: {
          title: lang.get("隱私保護"),
        },
        hidden: true,
      },
    ],
    meta: {
      title: lang.get("客服中心"),
    },
  },
  {
    path: "/download",
    component: Layout,
    children: [
      {
        path: "/download",
        component: () => import("../views/download/Download"),
        meta: {
          title: lang.get("遊戲下載"),
        },
      },
    ],
    meta: {
      title: lang.get("下載專區"),
    },
  },
  {
    path: "/shop",
    component: Layout,
    children: [
      {
        path: "/shop",
        redirect: "/shop/point",
        hidden: true,
      },
      {
        path: "/shop/point",
        component: () => import("../views/shop/Point"),
        meta: {
          title: lang.get("儲值點數"),
        },
      },
      {
        path: "/shop/prop",
        component: () => import("../views/shop/Prop"),
        meta: {
          title: lang.get("購買元寶"),
        },
      },
      {
        path: "/shop/record",
        component: () => import("../views/shop/Record"),
        meta: {
          title: lang.get("購買記錄"),
        },
      },
    ],
    meta: {
      title: lang.get("商城"),
    },
  },
  {
    path: "/event",
    component: Layout,
    children: [
      {
        path: "/event",
        component: () => import("../views/event/Event"),
        meta: {
          title: lang.get("活動"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("活動"),
    },
  },
  {
    path: "/kol",
    component: Layout,
    children: [
      {
        path: "/kol",
        component: () => import("../views/kol/Kol"),
        meta: {
          title: lang.get("活動"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("活動"),
    },
  },
  {
    path: "/luck",
    component: Layout,
    children: [
      {
        path: "/luck",
        component: () => import("../views/luck/Luck"),
        meta: {
          title: lang.get("活動"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("活動"),
    },
  },
  {
    path: "/server",
    component: Layout,
    children: [
      {
        path: "/server",
        component: () => import("../views/server/Server"),
        meta: {
          title: lang.get("活動"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("活動"),
    },
  },
  {
    path: "/new",
    component: Layout,
    children: [
      {
        path: "/new",
        component: () => import("../views/new/New"),
        meta: {
          title: lang.get("活動"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("活動"),
    },
  },
  {
    path: "/return",
    component: Layout,
    children: [
      {
        path: "/return",
        component: () => import("../views/return/Return"),
        meta: {
          title: lang.get("活動"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("活動"),
    },
  },
  {
    path: "/account",
    component: Layout,
    children: [
      {
        path: "/account",
        component: () => import("../views/account/Account"),
        meta: {
          title: lang.get("會員中心"),
        },
      },
      {
        path: "/account/login",
        component: () => import("../views/account/Login"),
        meta: {
          title: lang.get("登入"),
        },
      },
      {
        path: "/account/register",
        component: () => import("../views/account/Register"),
        meta: {
          title: lang.get("會員註冊"),
        },
      },
      {
        path: "/account/success",
        component: () => import("../views/account/Success"),
        meta: {
          title: lang.get("註冊成功"),
        },
      },
      {
        path: "/account/forgetaccount",
        component: () => import("../views/account/ForgetAccount"),
        meta: {
          title: lang.get("查詢帳號"),
        },
      },
      {
        path: "/account/forgetpassword",
        component: () => import("../views/account/ForgetPassword"),
        meta: {
          title: lang.get("查詢密碼"),
        },
      },
    ],
    hidden: true,
    meta: {
      title: lang.get("會員中心"),
    },
  },
  {
    path: "/promo",
    component: () => import("@/views/promo/MidAutumn"),
    hidden: true,
    meta: {
      title: "promo",
    },
  },
  {
    path: "/ad231116",
    meta: {},
    // component: () => import("../views/ad/2307001"),
    component: () => import("../views/ad/231116"),
    hidden: true,
  },
  {
    path: "/ad231116a",
    meta: {},
    // component: () => import("../views/ad/2307001"),
    component: () => import("../views/ad/231116"),
    hidden: true,
  },
  {
    path: "/ad231116b",
    meta: {},
    // component: () => import("../views/ad/2307001"),
    component: () => import("../views/ad/231116"),
    hidden: true,
  },
  {
    path: "/ad2307001",
    meta: {},
    // component: () => import("../views/ad/2307001"),
    component: () => import("../views/ad/2309001"),
    hidden: true,
  },
  {
    path: "/ad2307002",
    meta: {},
    component: () => import("../views/ad/2309001"),
    hidden: true,
  },
  {
    path: "/ad2307003",
    meta: {},
    component: () => import("../views/ad/2309001"),
    hidden: true,
  },
  {
    path: "/ad2408001",
    meta: {},
    component: () => import("../views/ad/2408001"),
    hidden: true,
  },
  {
    path: "/MoonFestival2023",
    meta: {},
    component: () => import("../views/moonfestival/index"),
    hidden: true,
  },
  {
    path: "/anniversary",
    meta: {},
    component: () => import("../views/anniversary/index"),
    hidden: true,
  },
  {
    path: "/flame",
    meta: {},
    component: () => import("../views/flame/index"),
    hidden: true,
  },
  {
    path: "/event240801",
    meta: {},
    component: () => import("../views/event/240801"),
    hidden: true,
  },
  {
    path: "/404",
    component: Layout,
    children: [
      {
        path: "/404",
        component: () => import("../views/notfound/NotFound"),
        meta: {
          title: "404",
        },
      },
    ],
    hidden: true,
    meta: {
      title: "404",
    },
  },
  {
    path: "/midautumn",
    component: Layout,
    children: [
      {
        path: "/midautumn",
        component: () => import("../views/promo/MidAutumn"),
        meta: {
          title: "midautumn",
          noLayout: true,
        },
      },
    ],
    hidden: true,
    meta: {
      title: "404",
    },
  },
  {
    path: "/:path(.+)",
    redirect: "/404",
    hidden: true,
  },
];

const router = createRouter({
  history: routerHistory,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRouterMap,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    window.document.title = `${lang.get("新墨魂")} - ${to.meta.title}`;
  } else {
    window.document.title = lang.get("新墨魂");
  }
  if (from.query.utm_source) {
    let utm_source = from.query.utm_source;
    if (Array.isArray(utm_source)) utm_source = utm_source[0];
    Cookies.set("utm_source", utm_source, {
      expires: new Date(new Date().getTime() + 604800000),
    });
  }
  if (from.query.utm_campaign) {
    let utm_campaign = from.query.utm_campaign;
    if (Array.isArray(utm_campaign)) utm_campaign = utm_campaign[0];
    Cookies.set("utm_campaign", utm_campaign, {
      expires: new Date(new Date().getTime() + 604800000),
    });
  }
  if (from.query.utm_medium) {
    let utm_medium = from.query.utm_medium;
    if (Array.isArray(utm_medium)) utm_medium = utm_medium[0];
    Cookies.set("utm_medium", utm_medium, {
      expires: new Date(new Date().getTime() + 604800000),
    });
  }
  next();
});

router.lang = lang;

export default router;
