<template>
  <div class="pang-search">
    <div class="select-container">
        <select @input="updateSearchType($event.target.value)" :value="searchType">
            <option v-for="item in searchTypes" :value="item" :key="item">
                {{ item }}
            </option>
        </select>
    </div>
    <div class="input-container">
        <input name="search" :placeholder="placeholder" :value="keyword" @input="updateKeyword($event.target.value)" @keyup.enter="gosearch"/>
    </div>
    <pang-butt :text="buttText" @click="gosearch" :style="{padding: '0', width: '90px'}" :loading="loading"/>
  </div>
</template>

<script>

import PangButt from './PangButt'
import lang from '../lang'

export default {
  name: 'PangSearch',
  components: {
    PangButt,
  },
  props: {
    searchTypes: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '| ' + lang.get('輸入關鍵字'),
    },
    keyword: String,
    searchType: String,
    buttText: {
      type: String,
      default: lang.get('搜索'),
    },
    loading: {
        type: Boolean,
        default: false,
    },
  },
  setup(props, { emit }) {
    const updateKeyword = value => {
        emit('update:keyword', value)
    }
    const updateSearchType = value => {
        emit('update:searchType', value)
    }
    return { updateKeyword, updateSearchType }
  },
  data () {
      return {
      }
  },
  methods: {
    gosearch () {
        this.$emit('search', { keyword: this.keyword, searchType: this.searchType })
    }
  },
}
</script>

<style scoped>

.pang-search {
    width: 100%;
    max-width: 482px;
    display: flex;
    height: 38px;
    line-height: 38px;
    border: 1px solid #C6A86C;
    border-radius: 19px;
    overflow: hidden;
    margin: auto;
}

.select-container {
    border-right: 1px solid #E3D8C6;
    width: 109px;
}

.select-container > select {
    border: none;
    width: 88px;
    color: #E4D8C5;
    text-align: center;
    padding: 0 20px;
    margin: 0 10px;
}

.input-container {
    width: calc(100% - 175px);
}

.input-container > input {
    margin: 0 10px;
    padding: 0 10px;
    width: calc(100% - 20px);
    border: none;
    color: #686A6E;
}

.input-container > input:focus, .select-container > select:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
}

.input-container > input::placeholder {
    font-weight: 300;
    color: #BFBFBF;
}

.butt-container {
    width: 65px;
    height: 38px;
    background: linear-gradient(132deg, #C6A86C 0%, #E0CDA5 100%);
    border-radius: 19px;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}
</style>
