import request from '../api/request'
import lang from '../lang'

const phoneRegex = /^(8869\d{8}|852(5|6|8|9)\d{7}|8536\d{7})$/
const codeRegex = /^\d{6}$/
const emailRegex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
const usernameRegex = /^[a-zA-Z][a-zA-Z\-_0-9]{4,}$/
const usernameLoginRegex = /^([a-zA-Z][a-zA-Z\-_0-9]{0,31}|9\d{8}|(5|6|8|9)\d{7}|[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)$/
const idcardRegex = /^([a-zA-Z][12][0-9]{8}|((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(([0−9aA])|([0-9aA]))|[1|5|7][0-9]{6}\([0-9Aa]\))$/
const passwordRegex = /^[\u0020-\u007e]+$/


function phone (str) {
    if (str.length <= 3)
        return lang.get('請輸入手機號碼')
    if (str.slice(0,4) == '8860')
        return lang.get('只需輸入0後面數字')
    if (!phoneRegex.test(str))
        return lang.get('手機號碼不正確，請重新輸入')
}

function code (str) {
    if (!str)
        return lang.get('請輸入驗證碼')
    if (!codeRegex.test(str))
        return lang.get('驗證碼為6位數字')
}

function email (str) {
    if (!str)
        return lang.get('請輸入信箱')
    if (!emailRegex.test(str))
        return lang.get('信箱格式有誤')
}

function username (str) {
    if (!str)
        return lang.get('請輸入帳號')
    if (str.length < 4)
        return lang.get('自訂帳號長度最少 4 字元')
    if (!usernameRegex.test(str))
        return lang.get('自訂帳號需為英文字母和數字的組合，且第一個字元必須為英文，英文及數字必須為半形')
    if (str.length > 32)
        return lang.get('自訂帳號長度最多為 32字元')
}

function usernameLogin (str) {
    if (!str)
        return lang.get('請輸入帳號')
    if (!usernameLoginRegex.test(str))
        return lang.get('請輸入正確帳號')
}

function password (str) {
    if (!str)
        return lang.get('請輸入密碼')
    if (str.length < 8)
        return lang.get('密碼長度最少為 8 字元')
    if (!passwordRegex.test(str))
        return lang.get('密碼不能有特殊字元')
}

function idcard (str) {
    if (!str)
        return lang.get('請輸入身份證號')
    if (!idcardRegex.test(str))
        return lang.get('身份證號格式有誤')
}

async function usernameRegister (str) {
    let res = username(str)
    if (res)
        return res
    res = await request.post('/register/unique', { username: str })
    if (res.status !== 200)
        return lang.get('帳號已被占用')
}

async function phoneAndUsernameRegister (str) {
    let res = phone(str)
    if (res)
        return res
    let username = str.slice(3)
    res = await request.post('/register/unique', { phone: username, username: username, areacode: str.slice(0, 3) })
    if (res.status !== 200) {
        if (res.data.detail.slice(0, 7) == '手機號已被占用')
            return lang.get('當前手機號碼已註冊')
        if (res.data.detail == '帳號已被占用')
            return lang.get('當前手機號與已有手機號衝突，請自訂帳號')
    }
}

async function phoneRegister (str) {
    let res = phone(str)
    if (res)
        return res
    res = await request.post('/register/unique', { phone: str.slice(3), areacode: str.slice(0, 3) })
    if (res.status !== 200)
        return lang.get('當前手機號碼已註冊')
}

async function emailRegister (str) {
    let res = email(str)
    if (res)
        return res
    res = await request.post('/register/unique', { email: str })
    if (res.status !== 200)
        return lang.get('輸入的Email已被註冊使用')
}

export default {
    phone,
    code,
    email,
    username,
    password,
    idcard,
    usernameRegister,
    phoneRegister,
    emailRegister,
    phoneAndUsernameRegister,
    usernameLogin,
}