<template>
    <div class="fullscreen" :class="isCollapse?'fullscreen-collapse':''">
        <div class="fullscreen-content">
            <div>
                <div>
                    <img class="pre-butt" @click="preMap" src="../assets/images/but_arrow_left.png"/>
                </div>
            </div>
            <div class="close-butt-container">
                <i class="fa fa-times" @click="closeFullScreen"></i>
            </div>
            <iframe
                :width="screenState.screenWidth * 0.8"
                :height="screenState.screenWidth * 0.4"
                :src="videoUrl"
            >
            </iframe>
            <div>
                <div>
                    <img class="next-butt" @click="nextMap" src="../assets/images/but_arrow_right.png"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useScreenState } from '../store';
import request from '../api/request'

export default {
    name: 'PangVideoFull',
    props: {
        videoUrl: {
            type: String,
            required: true
        },
    },
    data () {
        return {
            screenState: useScreenState(),
          staticUrl: request.staticUrl,
        };
    },
    methods: {
        preMap () {
            this.$emit('premap', {})
        },
        nextMap () {
            this.$emit('nextmap', {})
        },
        closeFullScreen () {
            this.$emit('close', {})
        },
    },
    computed: {
        isCollapse() {
        return this.screenState.screenWidth < 900;
        },
    },
}
</script>

<style scoped>

.fullscreen {
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.68);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.fullscreen-content {
    margin-top: 10vh;
    display: flex;
}

.fullscreen-content > div:first-child{
    flex: revert;
    margin: auto;
    margin-right: 6.25vw;
}

.fullscreen-content > div:last-child{
    flex: revert;
    margin: auto;
    margin-left: 1.41vw;
}

.pre-butt {
    max-width: 39px;
    max-height: 62px;
    width: 2.03vw;
    height: 3.23vw;
    cursor: pointer;
    border: none;
}

.next-butt {
    max-width: 39px;
    max-height: 62px;
    width: 2.03vw;
    height: 3.23vw;
    margin-left: 4.84vw;
    cursor: pointer;
    border: none;
}

.fullscreen-map {
    max-width: 956px;
    min-height: 20vw;
    width: 77.5vw;
}

.close-butt {
    max-width: 87px;
    max-height: 96px;
    width: 4.53vw;
    height: 5vw;
    cursor: pointer;
    border: none;
}

.close-butt-container {
    position: absolute;
    top: calc(10vh - 3vw);
    right: 3.25vw;
    cursor: pointer;
    font-size: 3vw;
}

.video-play-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.68);
  padding-top: 15vh;
  text-align: center;
  font-size: 50px;
}

.video-play-container > div {
  text-align: right;
  margin-right: 5vw;
}

.video-play-container i {
  cursor: pointer;
}

.video-collapse {
  font-size: 5vw;
}

</style>
