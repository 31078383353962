<template>
  <div :class="isCollapse?'collapse':''">
    <div
    class="input-but-container"
    :class="focus?'focus':''">
        <input
        :placeholder="placeholder"
        ref="input"
        @focus="inputFocus()"
        @blur="onBluring"
        @input="updateValue($event.target.value)"
        @keyup.enter="onEnter"
        :value="value" name="emailCode"/>
        <button @click="getCode" :disabled="screenState.buttMsg">{{ screenState.buttMsg?screenState.buttMsg:buttText }}</button>
    </div>
    <div v-if="message" class="error-message">{{ message }}</div>
    <div v-else class="normal-message">{{ hint }}</div>
  </div>
</template>

<script>

import Cookies from 'js-cookie'

import { useScreenState } from '../store'
import request from '../api/request'
import message from '../utils/message'
import validate from '../utils/validate'
import lang from '../lang'

export default {
  name: 'PangSmsEmail',
  props: {
    placeholder: {
      type: String,
      default: lang.get('輸入驗證碼'),
    },
    buttText: {
      type: String,
      default: lang.get('重新發送'),
    },
    value:  String,
    hint: String,
    email:  String,
    required: {
      type: Boolean,
      default: true,
    },
    verifyType: {
      type: Number,
      default: 1,
    },
    onEnter: {
      type: Function,
      default: function () {
      }
    },
    onBlur: {
      type: Function,
      default: function () {
      }
    },
  },
  data () {
      return {
            screenState: useScreenState(),
            focus: false,
            message: '',
      }
  },
  computed: {
      isCollapse() {
          return this.screenState.screenWidth < 900;
      },
  },
  setup(props, { emit }) {
    const updateValue = value => {
      emit('update:value', value)
    }
    return { updateValue }
  },
  methods: {
    onBluring(e) {
      let value = event.target.value
      let trimed = value.trim()
      if (trimed !== value) {
        value = trimed
        this.updateValue(value)
      }
      this.focus = false;
      this.message = this.onBlur(e.target.value)
    },
    inputFocus () {
        this.focus = true;
    },
    decreaseSecond () {
        if (this.screenState.buttMsg > 0) {
            this.screenState.buttMsg--
            setTimeout (this.decreaseSecond, 1000)
        }
        else
          this.screenState.buttMsgCounting = false
    },
    async validateValue(value) {
      let msg = validate.code(value)
      if (msg) {
        this.message = msg
        return false;
      }
      return true;
    },
    getCode () {
      if (!this.screenState.buttMsg) {
        let _this = this
        request.post('/verify/send', {email: this.email, verify_source: 2, verify_type: this.verifyType}, this).then(function (res) {
          if (res.status == 200) {
              message.showMessage(_this, lang.get('發送成功'), 'success')
          }
          else {
            message.resMessage(res, _this)
          }
        })
        this.screenState.buttMsg = 60
        Cookies.set('buttMsg', new Date().getTime(),  { expires: new Date(new Date().getTime() + 60000) })
        setTimeout (this.decreaseSecond, 1000)
        this.screenState.buttMsgCounting = true
      }
    },
  },
  mounted() {
    if (this.screenState.buttMsg > 0 && !this.screenState.buttMsgCounting) {
      setTimeout (this.decreaseSecond, 1000)
      this.screenState.buttMsgCounting = true
    }
  }
}
</script>

<style scoped>
  input {
    height: 44px;
    width: calc(100% - 156px);
    padding: 0 15px;
    border: none;
  }

  input:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
  }

  .focus {
    outline: none !important;
    border:1px solid #C26161;
    box-shadow: 0 0 5px #C7C7C7;
  }

  input::placeholder {
    font-weight: 300;
    color: #999999;
  }

  .input-but-container {
        border: 1px solid #C7C7C7;
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
  }

  .input-but-container button {
        height: 30px;
        background: #C6A86C;
        border-radius: 15px;
        font-size: 14px;
        color: #FFFFFF;
        width: 120px;
  }

  .input-but-container button:disabled {
        cursor: unset;
        background: #C7C7C7;
  }

  .error-message {
    color: #C26161;
    margin: 8px 4px 0 4px;
    height: 24px;
    text-align: left;
  }

  .normal-message {
    color: #939393;
    margin: 8px 4px 0 4px;
    height: 24px;
    text-align: left;
  }

  .collapse input {
    font-size: calc(1vw + 7px);
  }

</style>
