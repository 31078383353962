<template>
  <div v-if="btype == 'plain'" @click="fbqEvent" class="plain">
    <slot />
  </div>
  <div
    v-else
    class="pang-butt"
    :class="btype + (isCollapse ? ' collapse' : '')"
    :style="style"
    @click="fbqEvent"
  >
    <div v-if="loading" @click.stop="checkLoading">
      <i class="fa fa-spinner fa-spin" :class="icon ? '' : 'has-on-icon'"></i>
      {{ text }}
    </div>
    <div v-else>
      <i v-if="icon" :class="icon"></i>
      {{ text }}
    </div>
  </div>
</template>
<script>
import { useScreenState } from "../store";

const btype_regex = /^(plain|normal|submit|cancel|account-submit|account-cancel)$/;

export default {
  name: "PangButt",
  props: {
    text: String,
    btype: {
      type: String,
      default: "normal",
      validator: (val) => {
        return btype_regex.test(val);
      },
    },
    eventstr: String,
    style: {
      type: Object,
      default: function () {
        return {};
      },
    },
    icon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playVideo: false,
      screenState: useScreenState(),
    };
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
  },
  methods: {
    checkLoading() {},
    fbqEvent() {
      if (this.eventstr) {
        window.fbq("track", this.eventstr, {
          value: 0.01,
          currency: "USD",
        });
        window.gtag("event", this.eventstr); //註册成功的时候加个参数
      }
    },
  },
};
</script>

<style scoped>
.pang-butt {
  text-align: center;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pang-butt i {
  display: inline-block;
}

.has-on-icon {
  position: absolute;
  margin: 12px -20px;
}

.pang-butt img {
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
  display: inline-block;
}

.normal {
  height: 38px;
  line-height: 38px;
  background: linear-gradient(132deg, #c5a76c 0%, #e0cca4 100%);
  border-radius: 19px;
  font-size: 14px;
  padding: 0 29px;
}

.submit {
  background-color: #c6a86c;
  margin: auto;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
}

.submit .has-on-icon,
.cancel .has-on-icon {
  margin: 13px -20px;
}

.cancel {
  background-color: #dcdcdc;
  margin: auto;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
}

.account-submit,
.account-cancel {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  font-weight: 300;
  color: #ffffff;
  font-size: 22px;
}

.account-submit {
  background: #2c82ff;
}

.account-cancel {
  background-color: #c6c9ce;
}

.account-submit .has-on-icon,
.account-cancel .has-on-icon {
  margin: 22px -30px;
}

.collapse {
  font-size: calc(1vw + 4px);
  padding: 0 calc(2vw + 11px);
  height: 30px;
  line-height: 30px;
}

.collapse .account-submit,
.collapse .account-cancel {
  width: 100%;
  height: calc(3vw + 33px);
  line-height: calc(3vw + 33px);
  border-radius: 4px;
  font-weight: 300;
  color: #ffffff;
  font-size: calc(1vw + 13px);
  padding: 0;
}

.collapse .cancel,
.collapse .submit {
  height: calc(2vw + 22px);
  line-height: calc(2vw + 22px);
  font-size: calc(1vw + 5px);
  padding: 0;
}

.plain {
  display: inline-block;
  margin: auto;
}
</style>
