
import Language from './lang'

export default new Language({
    NO_DATA: '暫無數據',
    LEAVE_MESSAGE: '上留言',
    LOGIN_AND_LEAVE_MESSAGE: '登入留言',
    REPLY: '回復',
    MORE: '更多',
    RICH_TEXT_EDITOR_HINT: '發佈辱罵,相同文章重覆發文,誹謗,謠言等違反營運政策的貼文時，可能會受到限制發文，若有違法情事發生，自行負擔法律相關責任.',
    RICH_TEXT_EDITOR_HINT_MAX_LENGTH: '最長100個字元',
    CANCEL: '取消',
    CONFIRM: '確認',
    PLEASE_ENTER: '請輸入',
    NUMBER_OF_WORD_LESS_THAN:  '字數不足',
    NUMBER_OF_WORD_MORE_THAN:  '字數超過',
    ENTER_LINK: '輸入鏈接',
    UPLOAD_IMAGE: '上載圖像',
    PRE_PAGE: '上一頁',
    NEXT_PAGE: '下一頁',
    BUY: '購買',
    ENTER_KEYWORD: '輸入關鍵字',
    SEARCH: '搜索',
    ENTER_VERIFICATION_CODE: '輸入驗證碼',
    RESENT: '重新發送',
    SEND_SUCCEEDED: '發送成功',
    PLEASE_ENTER_PHONE_NUMER: '請輸入手機號碼',
    GET_VERIFICATION_CODE: '獲取驗證碼',
    PLEASE_UPLOAD_IMAGE: '请上載圖像',
    MORE_THAN_5_M: '大於 5 M',
    UPLOAD_FAILED: '上載失敗',
    OFFICIAL_WEBSITE_HOMEPAGE: '官網首頁',
    WHATS_NEW: '最新消息',
    NOTICE: '公告',
    ACTIVITY: '活動',
    UPDATE: '更新',
    GAME_INFO: '遊戲資訊',
    GAME_MAP: '遊戲地圖',
    GAME_GUIDE: '遊戲指南',
    PLAYER_DISCUSSION_SECTION: '玩家討論區',
    STRATEGY_DISCUSSION_SECTION: '攻略討論區',
    PICTURE_SHARE_SECTION: '圖片分享區',
    COMMUNITY: '社群',
    ONE_TO_ONE_INQUIRY: '1:1詢問',
    MEMBERSHIP_TERMS: '會員條款',
    CUSTOMER_SERVICE: '客服中心',
    GAME_DOWNLOAD: '遊戲下載',
    DOWNLOAD_ZONE: '下載專區',
    RECHARGE_POINT: '儲值點數',
    PURCHASE_YUANBAO: '購買元寶',
    PURCHASE_RECORD: '購買記錄',
    SHOP: '商城',
    MEMBERSHIP_CENTER: '會員中心',
    LOGIN: '登入',
    MEMBERSHIP_REGISTER: '會員註冊',
    REGISTER_SUCCEEDED: '註冊成功',
    RETRIEVE_ACCOUNT: '查詢帳號',
    RETRIEVE_PASSWORD: '查詢密碼',
    HOME_TITLE: '新墨魂',
    BEGINNER_GUIDE: '新手指南',
    EQUIPMENT_AND_SKILL: '裝備技能',
    ADVANCED_STRATEGY: '進階攻略',
    UNPAID: '未付',
    PAYING: '支付中',
    PAID: '已付',
    ABNORMAL: '交易失敗',
    CHAT: '聊天',
    QUESTION: '問題',
    SUGGESTION: '建議',
    MEMBERSHIP_AND_ACCOUNT: '會員帳號',
    INSTALL_AND_EXECUTE: '安裝/執行',
    RECHARGE_AND_PURCHASE: '儲值/購買',
    GAME_PROBLEM: '遊戲問題',
    OTHERS: '其他',
    RECHARGE: '儲值',
    MEMBERSHIP_DATA: '會員資料',
    GAME_USAGE: '遊戲使用',
    OPERATION: '運營',
    SWORD_SYSTEM: '劍系',
    SPEAR_SYSTEM: '槍系',
    KNIFE_SYSTEM: '刀系',
    ARCH_SYSTEM: '弓系',
    FIST_SYSTEM: '拳系',
    HIDDEN_WEAPON: '暗器',
    PHONE_NUMBER_INCORRECT_PLEASE_REENTER: '手機號碼不正確，請重新輸入',
    PLEASE_ENTER_VERIFICATION_CODE: '請輸入驗證碼',
    VERIFICATION_CODE_FORMAT_INCORRECT: '驗證碼格式有誤',
    PLEASE_ENTER_EMAIL: '請輸入信箱',
    EMAIL_FORMAT_INCORRECT: '信箱格式有誤',
    PLEASE_ENTER_ACCOUNT: '請輸入帳號',
    FIRST_CHAR_MUST_BE_ENGLISH_LETTER: '第一个字元必须為英文',
    ACCOUNT_LENGTH_LESS_THAN_32: '帳號長度最多為 32',
  })