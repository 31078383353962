<template>
  <div
  class="pang-banner"
  :style="{'height': height}"
  v-if="images.length > 0"
  @mouseover="pause"
  @mouseleave="play">
    <transition name="fade">
        <div
        @click="openLink(images[offset].url)"
        v-if="show"
        class="image-container"
        :class="images[offset].url?'has-link':''"
        :style="'background: #fff url(\'' + staticUrl + (isCollapse && images[offset].cover_mobile?images[offset].cover_mobile:images[offset].cover)+'\') no-repeat center;background-size: cover;'">
        </div>
    </transition>
    <div class="butt" v-if="images.length > 1 && !isCollapse">
        <div v-for="i in images.length" :key="i" @click.stop="setOffset(i-1)">
            <div 
            class="butt-item"
            :class="offset === i-1?'active':''"></div>
        </div>
    </div>
  </div>
</template>

<script>


import { useScreenState } from '../store';
import browser from '../utils/browser';
import request from '../api/request'

export default {
  name: 'PangBanner',
  props: {
    images: Array,
    interval: {
        type: Number,
        default: 5000,
    },
    height: {
        type: String,
        default: '873px',
    },
  },
  data () {
      return {
        screenState: useScreenState(),
          offset: 0,
          show: true,
          paused: false,
          staticUrl: request.staticUrl,
      }
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 767;
    },
  },
  mounted () {
      let _this = this
      function autoplay () {
        if (!_this.paused && _this.images.length > 1)
            _this.next()
        setTimeout (autoplay, _this.interval)
        }
        setTimeout (autoplay, this.interval)
  },
  methods: {
    pause () {
      this.paused = true
    },
    play () {
      this.paused = false
    },
      next () {
          this.setOffset((this.offset + 1) % this.images.length)
      },
      setOffset (i) {
          this.show = false
          let _this = this
          setTimeout(function () {
            _this.offset = i
          _this.show = true
          }, 500)
      },
      openLink (url) {
          if (url) {
            if (browser.isMobile)
                window.location = url
            else
                window.open(url)
          }
      }
  }
}
</script>

<style scoped>
.image-container {
    height: 100%;
    width: 100%;
    display: inline-block;
    background-size: 100% 100%;
}

.has-link {
    cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
    transition:all 1s ease;
    transition:translateX(0%);
}
.fade-enter, .fade-leave-to {
    transition:translateX(100%);
    width: 0;
}

.butt {
    position: absolute;
    margin-top: calc(-137/1920*100vw - 60px);
    height: 40px;
    width: 100%;
    display: flex;
    z-index: 100;
}

.butt > div {
    padding: 25px;
    cursor: pointer;
}

.butt-item {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #cccccc;
    margin: auto;
}

.active {
    background-color: #C6A86C;
}

.butt > div:first-child {
    margin-left: auto;
}

.butt > div:last-child {
    margin-right: auto;
}
</style>
