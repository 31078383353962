<template>
  <div class="pang-title" :class="isCollapse?'collapse':''">
    <div>
        {{ text }}
    </div>
    <div>
        <span>/</span>
        <span>{{ eng }}</span>
        <span>/</span>
    </div>
  </div>
</template>

<script>

import { useScreenState } from "../store";

export default {
  name: 'PangTitle',
  props: {
    text: {
      type: String,
      required: true,
    },
    eng: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      screenState: useScreenState(),
    };
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
  },
}
</script>

<style scoped>

.pang-title {
  width: 90vw;
  max-width: 640px;
  margin: 29px auto;
  text-align: center;
}

.pang-title > div:first-child {
  font-size: 52px;
  font-weight: normal;
  color: #C6A86C;
}

.pang-title span {
  font-size: 21px;
  font-weight: normal;
  color: #B4B6B9;
  margin: 0 12px;
}

.collapse > div:first-child {
  font-size: 6vw;
}

.collapse  span {
  font-size: 3vw;
}
</style>
