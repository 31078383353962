<template>
  <div class="pang-tab" :class="styleType + (isCollapse?' collapse':'')">
      <div
      v-for="(tab, i) in tabs" :key="tab"
      :class="i === current?'active':'inactive'"
      @click="updateCurrent(i)"
      >{{ tab }}</div>
  </div>
</template>

<script>
import { useScreenState } from "../store";

export default {
  name: 'PangTab',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      default: 0,
    },
    styleType: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const updateCurrent = current => {
      emit('update:current', current)
    }
    return { updateCurrent }
  },
  data() {
    return {
      playVideo: false,
      screenState: useScreenState(),
    };
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
  },
}
</script>

<style scoped>
.pang-tab {
    font-size: 18px;
    display: flex;
    -webkit-user-select:none;
   -moz-user-select:none;
   -ms-user-select:none;
   user-select:none;
}

.pang-tab > div {
    line-height: 59px;
    max-width: 120px;
    width: 20.27vw;
    text-align: center;
    margin-right: 2.23vw;
}

.active {
    color: #C6A86C;
    border-bottom: 3px solid #C6A86C;
}

.inactive {
    color: #686A6E;
    cursor: pointer;
}

.butt .active {
  background: #C6A86C;
  color: #fff;
  border-bottom: none;
}

.butt .inactive {
  border: 1px solid #A4A4A4;
}

.butt  > div {
    line-height: 30px;
    width: 120px;
    height: 30px;
    font-size: 14px;
    border-radius: 15px;
    text-align: center;
    margin-right: 20px;
}

.collapse {
  font-size: calc(1vw + 9px);
}

.collapse .butt > div {
    width: calc(7vw + 57px);
    height: calc(1.6vw + 15.6px);
    border-radius: calc(0.8vw + 7.8px);
    line-height: calc(1.6vw + 15.6px);
    margin-right: calc(1vw + 11px);
    font-size: calc(1vw + 5px);
    margin-top: calc(1vw + 13px);
}

</style>
