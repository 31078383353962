<template>
    <div class="pang-dialog" :class="isCollapse?'collapse':''">
        <div>
            <div class="title">{{ title }}</div>
            <div class="body">
                <slot/>
            </div>
            <div class="butt-container">
                <pang-butt @click="cancel" :text="cancelText" btype="cancel" :loading="loading"/>
                <pang-butt @click="submit" :text="submitText" btype="submit" :loading="loading"/>
            </div>
        </div>
    </div>
</template>

<script>

import { useScreenState } from '../store';
import PangButt from './PangButt'

export default {
    name: 'PangDialog',
    components: {
        PangButt,
    },
    props: {
        title: String,
        cancelText: String,
        submitText: String,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            screenState: useScreenState(),
        }
    },
    computed: {
        isCollapse() {
        return this.screenState.screenWidth < 900;
        },
    },
    methods: {
        cancel (e) {
            this.$emit('cancel', e)
        },
        submit (e) {
            this.$emit('submit', e)
        },
    },
}
</script>

<style scoped>

.pang-dialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.68);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    flex-direction: column;
    display: flex;
}


.pang-dialog > div {
    background-color: #fff;
    border-radius: 4px;
    font-size: 36px;
    color: #595959;
    max-width: 391px;
    width: calc(90vw - 66px);
    margin: auto;
    padding: 33px;
}

.collapse .butt-container > div {
    width: calc(15vw + 45px);
}

.title {
    text-align: center;
}

.butt-container {
    display: flex;
}

.butt-container > div {
    width: 180px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    cursor: pointer;
}

.cancel-butt {
    background-color: #DCDCDC;
    margin: auto;
    margin-left: 0;
}

.submit-butt {
    background-color: #C6A86C;
    margin: auto;
    margin-right: 0;
}

.body {
    min-height: 100px;
    font-size: 16px;
    font-weight: 300;
    color: #999999;
    line-height: 22px;
    margin: 13px;
}

</style>
