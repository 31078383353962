<template>
    <div class="pang-article" :class="isCollapse?'article-collapse':''">
        <div v-if="articles.length > 0" class="articles-container">
            <div v-for="article in articles" :key="article.id">
                <div class="article-type" :class="isCollapse?'collapse':''">
                    <div>{{ article.type }}</div>
                </div>
                <div class="article-detail">
                    <div>
                        <div
                        class="article-title"
                        @click="open(article.href)"
                        :title="article.title">
                            [{{ article.type }}]{{ article.title }}
                        </div>
                        <div v-if="!isCollapse" class="article-date">{{ article.create_at.substr(0, 10) }}</div>
                    </div>
                    <div>{{ article.abstract }}</div>
                </div>
            </div>
        </div>
        <div class="no-data" v-else>
            {{ $router.lang.get('暫無數據') }}
        </div>
        <pang-pager v-if="totalPages > 1" @change="goto" :totalPages="totalPages" :currentPage="currentPage"/>
    </div>
</template>

<script>

import { useScreenState } from '../store';
import PangPager from './PangPager';
import browser from '../utils/browser';

export default {
    name: 'PangArticle',
        components: {
            PangPager,
        },
    props: {
        totalPages: {
        type: Number,
        required: true,
        },
        currentPage: {
        type: Number,
        default: 1,
        },
        articles: {
        type: Array,
        required: true,
        },
    },
    setup(props, { emit }) {
        const goto = value => {
        emit('update:currentPage', value)
        emit('change', value)
        }
        return { goto }
    },
    data () {
        return {
            current: 1,
            screenState: useScreenState(),
        }
    },
    computed: {
        isCollapse() {
        return this.screenState.screenWidth < 900;
        },
    },
    methods: {
        open (url) {
            if (browser.isPc)
                window.open(url)
            else
                this.$router.push(url)
        },
    },
}
</script>

<style scoped>
.articles-container {
    margin-top: 54px;
    margin-bottom: 48px;
    color: #686A6E;
}
.articles-container > div {
    display: flex;
    margin-bottom: 32px;
    width: 100%;
}

.article-type {
    height: 92px;
    width: 65px;
    padding-right: 39px;
    border-right: 1px solid#D6E0EB;
    font-size: 14px;
    color: #FFFFFF;
}

.article-type > div{
    width: 65px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    background: linear-gradient(132deg, #C6A86C 0%, #E0CDA5 100%);
    border-radius: 15px;
    margin-top: 32px;
}

.article-detail {
    margin-left: 41px;
    width: calc(100% - 145px);
}

.article-detail > div:first-child{
    display: flex;
    margin: 13px 0;
}

.article-detail > div:last-child, .article-title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.article-title {
    font-size: 22px;
    font-weight: 400;
    padding-right: 20px;
    cursor: pointer;
}

.article-date {
    color: #A8AEB3;
    line-height: 32px;
    margin-left: auto;
}

.collapse {
    padding-right: 0;
    border-right: none;
}

.no-data {
    font-size: 40px;
    text-align: center;
    margin-top: 150px;
    color: #A8AEB3;
}

.article-collapse .article-title {
    font-size: calc(1vw + 13px);
}

.article-collapse .article-detail > div {
    font-size: calc(1vw + 7px);
}

.article-collapse .articles-container > div {
    margin-bottom: calc(3vw + 5px);
}

.article-collapse .article-detail {
    margin-left: calc(4vw + 5px);
    width: calc(100% - 100px - 4vw);
}

</style>
