
function zfill(digit, value) {
    value = String(value)
    let res = Array.from({length: digit - value.length}, () => '0')
    res.push(value)
    return res.join('')
}

export default {
    priceFormat: (value) => {
        let res = ''
        while (value >= 1000) {
            res = ',' + zfill(3, value % 1000) + res
            value = Math.floor(value / 1000)
        }
        return '' + value + res
    },
    dateFormat: (date) => {
        let year = date.getFullYear()
        let month = zfill(2, date.getMonth() + 1)
        let day = zfill(2, date.getDate())
        let hour = zfill(2, date.getHours())
        let minute = zfill(2, date.getMinutes())
        let second = zfill(2, date.getSeconds())
        return '' + year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    },
}