
function isPC() {
    let u = window.navigator.userAgent
    return !u.match(/AppleWebKit.*Mobile.*|Linux/)
}

function isIOS() {
    let u = window.navigator.userAgent
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

function isChrome() {
    let u = window.navigator.userAgent
    return !!u.match(/.*Chrome.*/)
}

export default { 
    isPc: isPC(),
    isIOS: isIOS(),
    isChrome: isChrome(),
}