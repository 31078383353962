<template>
  <div class="pang-input" :class="isCollapse?'collapse':''">
    <input
    :placeholder="placeholder"
    ref="input"
    @blur="onBlur"
    @input.stop="updateValue($event.target.value)"
    @keyup.enter="onEnter"
    :value="value" :name="name" :type="type"/>
    <div v-if="message" class="error-message">{{ message }}</div>
    <div v-else class="normal-message">{{ hint }}</div>
  </div>
</template>

<script>

import lang from '../lang'
import { useScreenState } from '../store';

export default {
  name: 'PangInput',
  props: {
    placeholder: {
      type: String,
      default: lang.get('請輸入'),
    },
    name: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    value:  String,
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Function,
      default: function (value, _this) {
        if (_this.required && !value)
          return lang.get('請輸入')
      }
    },
  },
  setup(props, { emit }) {
    const updateValue = value => {
      emit('update:value', value)
      emit('input', value)
    }
    return { updateValue }
  },
  data() {
    return {
      screenState: useScreenState(),
      message: '',
      inputValue: this.value,
    }
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
  },
  methods: {
    onBlur(event) {
      let value = event.target.value
      if (this.type != 'password') {
        let trimed = value.trim()
        if (trimed !== value) {
          value = trimed
          this.updateValue(value)
        }
      }
      this.validateValue(value)
    },
    updateMsg (message) {
      if (message) {
        this.message = message
        return false
      }
      this.message = ''
      return true
    },
    async validateValue (value) {
      let message = this.validate(value, this)
      if (message && message.then) {
        message = await message
      }
      return this.updateMsg(message)
    },
    onEnter () {
      this.$emit('enter', { value: this.value })
    },
    focus () {
      this.$refs.input.focus()
    },
  },
}
</script>

<style scoped>

  input {
    height: 44px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    width: calc(100% - 30px);
    padding: 0 15px;
  }

  input:focus {
    outline: none !important;
    border:1px solid #C26161;
    box-shadow: 0 0 5px #C7C7C7;
  }

  input::placeholder {
    font-weight: 300;
    color: #999999;
  }

  .error-message {
    color: #C26161;
    margin: 8px 4px 0 4px;
    height: 24px;
  }

  .normal-message {
    color: #939393;
    margin: 8px 4px 0 4px;
    height: 24px;
  }

.collapse input {
  font-size: calc(1vw + 7px);
}

</style>
