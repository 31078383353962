<template>
    <div class="pang-article-detail">
        <div class="title">
            <h1>{{ title }}</h1>
            <div v-if="create_at" class="pubtime"><span>{{ author}} </span><span>{{ create_at.substr(0, 10) }}</span></div>
        </div>
        <div class="content" v-html="content">
        </div>
    </div>
</template>

<script>

export default {
    name: 'PangArticleDetail',
    props: {
        title: {
            type: String,
            required: true,
        },
        author: {
            type: String,
            required: false,
        },
        create_at: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
    },
}
</script>

<style>
.content img{
    max-width: 100%;
}
</style>

<style scoped>

.pang-article-detail {
    max-width: 1000px;
    width: 90vw;
    color: #686A6E;
    margin: auto;
}
.title {
    text-align: center;
    margin-top: 60px;
}

.title > h1 {
    font-size: 28px;
    font-weight: 400;
}

.title > div {
    color: #A8AEB3;
}

.content {
    margin: 40px;
    min-height: 300px;
    word-wrap: break-word;
    word-break: normal; 
}

.pubtime > span {
    padding: 10px;
}

</style>
