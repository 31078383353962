
class Language {
    constructor (data) {
        this.$data = data;
    }
    format (res, data) {
        for (let i in data) {
            res = res.replace(RegExp('\\{\\s*' + i + '\\s*\\}', 'g'), data[i])
        }
        return res
    }
    get (name, data=null) {
        let res = name;
        if (this.$data[name])
            res = this.$data[name];
        if (data)
            res = this.format(res, data)
        return res;
    }
}

export default Language