<template>
  <div class="app-wrapper">
    <div
      class="main-container"
      :class="isCollapse ? 'collapse' : ''"
      @click="containerClicked"
      v-if="noLayout"
    >
      <pang-message />
      <app-main />
    </div>
    <div
      class="main-container"
      :class="isCollapse ? 'collapse' : ''"
      @click="containerClicked"
      v-else
    >
      <div
        v-if="bookingShow && !screenState.hideFooter"
        class="booking-container"
        :class="
          (isCollapse ? 'collapse' : '') +
            (screenState.hideBreadcrumb ? ' hide-bread' : '')
        "
        :style="{ backgroundImage: 'url(' + staticUrl + bookingImage + ')' }"
      >
        <div class="booking-close" @click="closeBooking"></div>
        <div class="booking-butt" @click="goBook"></div>
      </div>
      <pang-message />
      <navbar ref="navbar" v-if="!screenState.hideBreadcrumb" />
      <breadcrumb v-if="!screenState.hideBreadcrumb" />
      <app-main />
      <app-footer v-if="!screenState.hideFooter" />
        <connectUs />
    </div>
  </div>
</template>

<script>
import { Navbar, Breadcrumb, AppMain, AppFooter } from "./components";
import { useScreenState } from "../../store";
import request from "../../api/request";
import connectUs from "@/components/connect_us";

import { PangMessage } from "../../components";

export default {
  name: "Layout",
  components: {
    Navbar,
    Breadcrumb,
    AppMain,
    AppFooter,
    connectUs,
    PangMessage,
  },

  data() {
    return {
      screenState: useScreenState(),
      bookingShow: false,
      bookingImage: "",
      staticUrl: request.staticUrl,
    };
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
    noLayout() {
      return this.$route.meta.noLayout;
    },
  },
  methods: {
    containerClicked() {
      if (this.isCollapse && this.$refs.navbar) {
        this.$refs.navbar.menusShow = false;
      }
    },
    closeBooking() {
      this.bookingShow = false;
    },
    goBook() {
      if (!this.screenState.user) {
        this.$router.push("/account/register");
      } else {
        this.$router.push("/download");
      }
    },
    loadTawkTo() {
      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_LoadStart = new Date();
      (function() {
        let s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/5fd888eba8a254155ab37ed3/1epitdn2n";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    },
  },
  mounted() {
    let _this = this;
    if (!this.screenState.hideFooter)
      if (this.screenState.user) {
        // this.loadTawkTo()
        request.get("/users/me", {}).then(function(res) {
          if (res.status == 200) {
            _this.screenState.user = res.data;
            window.localStorage.setItem("user_info", JSON.stringify(res.data));
          } else if (res.status == 404) {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("user_info");
            _this.$router.push("/account/login");
          }
        });
      }
    request.get("/banners", { banner_type: 5 }).then(function(res) {
      if (res.status == 200 && res.data.results.length > 0) {
        _this.bookingImage = res.data.results[0].cover;
        _this.bookingShow = true;
        window.addEventListener("message", function(e) {
          if (e.data.source == "smhtw.com" && e.data.message == "closeFloat") {
            _this.bookingShow = false;
          }
        });
      }
    });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.main-container {
  background-color: #fff;
  background-image: url("../../assets/images/bg_neirong_top.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.collapse {
  font-size: calc(1vw + 7px);
}

.booking-container {
  position: fixed;
  width: 17.19vw;
  height: 13.86vw;
  top: 70px;
  right: -1px;
  z-index: 1001;
  background-size: 100% 100%;
}

.booking-close {
  margin: 0.52vw 0.52vw 0 auto;
  height: 1.67vw;
  width: 1.67vw;
  background: url("~@/assets/images/btn_close_suspension.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.booking-butt {
  height: 10.37vw;
  width: 100%;
  cursor: pointer;
}

.collapse .booking-container {
  height: 166.33px;
  width: 206.25px;
}

.collapse .booking-close {
  margin: 6.25px 6.25px 0 auto;
  height: 20px;
  width: 20px;
}

.collapse .booking-butt {
  height: 128.75px;
  width: 100%;
}

@-webkit-keyframes anim1 {
  0% {
    top: 70px;
    opacity: 1;
  }
  25% {
    top: 85px;
    opacity: 1;
  }
  50% {
    top: 100px;
    opacity: 1;
  }
  75% {
    top: 85px;
    opacity: 1;
  }
  100% {
    top: 70px;
    opacity: 1;
  }
}

@-webkit-keyframes anim2 {
  0% {
    top: 110px;
    opacity: 1;
  }
  25% {
    top: 125px;
    opacity: 1;
  }
  50% {
    top: 140px;
    opacity: 1;
  }
  75% {
    top: 125px;
    opacity: 1;
  }
  100% {
    top: 110px;
    opacity: 1;
  }
}

.booking-container {
  -webkit-animation: anim1 2s linear infinite;
}

.hide-bread {
  top: 110px;
  z-index: 1001;
  -webkit-animation: anim2 2s linear infinite;
}
</style>
