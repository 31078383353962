<template>
    <div class="pang-date">
        <div class="date-input">
            <input type="date" :value="startDate" @input="updateStartDate($event.target.value)">
        </div>
        <div class="to-contaner">~</div>
        <div class="date-input">
            <input type="date" :value="endDate" @input="updateEndDate($event.target.value)">
        </div>
    </div>
</template>

<script>

export default {
    name: 'PangDate',
    props: {
        startDate: String,
        endDate: String,
    },
    setup(props, { emit }) {
        const updateStartDate = value => {
        emit('update:startDate', value)
        }
        const updateEndDate = value => {
        emit('update:endDate', value)
        }
        return { updateStartDate,  updateEndDate}
    },
}
</script>

<style scoped>

.pang-date {
    display: flex;
}

.date-input input {
    font-size: 16px;
    color: #E4D8C5;
    border: 1px solid #C6A86C;
    opacity: 0.3;
    border-radius: 19px;
    padding: 0 15px;
    height: 38px;
    width: 160px;
}
.to-contaner {
    line-height: 38px;
    margin: 0 13px;
}

input:focus {
    outline: none !important;
    border:1px solid #C26161;
    box-shadow: 0 0 5px #C7C7C7;
}
</style>
