<template>
  <div class="header" :class="isCollapse ? '' : 'fixed'">
    <div v-if="isCollapse" class="menu-icon-container">
      <i @click.stop="showMenus" class="menu-icon fa fa-bars" />
    </div>
    <div class="logo-container">
      <img
        @click="goto('/home')"
        class="logo"
        src="../../../assets/images/img_logo_header.png"
      />
    </div>
    <div class="user-container">
      <div>
        <div
          style="border-right: none"
          v-if="screenState.screenWidth < 1200"
          @mouseleave="unshowDetail"
          @mouseover="showDetail"
        >
          <pang-butt btype="plain" eventstr="signin_nav">
            <img
              @click="goto(screenState.user ? '/account' : '/account/login')"
              class="user-icon icon-alone"
              src="../../../assets/images/icon_signup.png"
            />
          </pang-butt>
          <div
            v-if="detailShow && screenState.user && !isCollapse"
            class="user-detail-placeholder"
          ></div>
          <div>
            <div
              v-if="detailShow && screenState.user && !isCollapse"
              class="user-detail user-detail-mobile"
            >
              <div class="light"></div>
              <div class="links-container">
                <div @click="goto('/account')">
                  {{ $router.lang.get("我的資料") }}
                </div>
                <div @click="goto('/account?tab=1')">
                  {{ $router.lang.get("密碼更改") }}
                </div>
              </div>
              <div class="points-container">
                <div>
                  {{
                    $router.lang.get("我的點数：{point}", {
                      point: priceFormat(screenState.user.total_point),
                    })
                  }}
                  <i
                    :title="
                      $router.lang.get(
                        '玩家必須先儲值點數，方可在 購買元寶 頁面購買新墨魂Online的元寶'
                      )
                    "
                    >?</i
                  >
                </div>
                <div>
                  <div>{{ $router.lang.get("購買P-point後") }}</div>
                  <div>{{ $router.lang.get("商城→購買元寶") }}</div>
                </div>
                <div @click="goto('/shop/point')">
                  {{ $router.lang.get("儲值點數") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div
            v-if="screenState.user"
            @mouseleave="unshowDetail"
            @mouseover="showDetail"
          >
            <span>{{ screenState.user.nickname }}</span>
            <div v-if="detailShow" class="user-detail-placeholder"></div>
            <div v-if="detailShow" class="user-detail">
              <div class="light"></div>
              <div class="links-container">
                <div @click="goto('/account')">
                  {{ $router.lang.get("我的資料") }}
                </div>
                <div @click="goto('/account?tab=1')">
                  {{ $router.lang.get("密碼更改") }}
                </div>
              </div>
              <div class="points-container">
                <div>
                  {{
                    $router.lang.get("我的點数：{point}", {
                      point: priceFormat(screenState.user.total_point),
                    })
                  }}
                  <i
                    :title="
                      $router.lang.get(
                        '玩家必須先儲值點數，方可在 購買元寶 頁面購買新墨魂Online的元寶'
                      )
                    "
                    >?</i
                  >
                </div>
                <div>
                  <div>{{ $router.lang.get("購買P-point後") }}</div>
                  <div>{{ $router.lang.get("商城→購買元寶") }}</div>
                </div>
                <div @click="goto('/shop/point')">
                  {{ $router.lang.get("儲值點數") }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <img
              class="user-icon"
              src="../../../assets/images/icon_signup.png"
            />
            <pang-butt btype="plain" eventstr="signup_nav">
              <span class="cursor" @click="goto('/account/register')">{{
                $router.lang.get("會員註冊")
              }}</span>
            </pang-butt>
          </div>
          <div>
            <img
              class="user-icon"
              src="../../../assets/images/icon_signin.png"
            />
            <span class="cursor" v-if="screenState.user" @click="logout()">{{
              $router.lang.get("登出")
            }}</span>
            <pang-butt v-else btype="plain" eventstr="signin_nav">
              <span class="cursor" @click="goto('/account/login')">{{
                $router.lang.get("登入")
              }}</span>
            </pang-butt>
          </div>
        </template>
      </div>
      <pang-butt btype="plain" eventstr="download_nav">
        <div
          v-if="!isCollapse"
          @click="goto('/download')"
          class="download-container"
        >
          <div>{{ $router.lang.get("遊戲下載") }}</div>
          <div>{{ $router.lang.get("windows遊戲主程式") }}</div>
        </div>
      </pang-butt>
    </div>
    <div
      @mouseover.prevent="openSubmenu"
      @mouseleave="closeSubmenu"
      :class="
        isCollapse
          ? 'menu-container-mobile'
          : 'menu-container' + (screenState.screenWidth < 1200 ? ' mini' : '')
      "
      v-show="!isCollapse || menusShow"
    >
      <div
        v-for="item in screenState.menus"
        :key="item.text"
        :class="item.isActive ? 'active-menu' : ''"
        @mouseover="highlineSubmenu(item)"
        @mouseleave="unhighlineSubmenu(item)"
      >
        <div class="menu-title" @click="goto(item.href)">{{ item.text }}</div>
        <div class="menu-items">
          <div
            v-for="subitem in item.submenus"
            :key="subitem.text"
            :class="subitem.isActive ? 'active-menu-item' : ''"
            @click="goto(subitem.href)"
            @mouseover="highlineSubmenuItem(subitem)"
            @mouseleave="unhighlineSubmenuItem(subitem)"
          >
            {{ subitem.text }}
          </div>
        </div>
        <div
          class="gradient-back"
          :class="screenState.screenWidth < 1842.7 ? '' : 'gradient-back-max'"
        >
          <div></div>
        </div>
      </div>
      <transition name="draw" v-if="!isCollapse">
        <section class="submenu-container" v-show="submenuShowed"></section>
      </transition>
    </div>
  </div>
</template>

<script>
import { useScreenState } from "../../../store";
import filters from "../../../utils/filters";
import { PangButt } from "../../../components";
import message from "../../../utils/message";

export default {
  name: "Navbar",
  components: {
    PangButt,
  },
  data() {
    return {
      screenState: useScreenState(),
      menusShow: document.body.clientWidth >= 900,
      submenuShowed: false,
      detailShow: false,
    };
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
  },
  methods: {
    priceFormat: filters.priceFormat,
    openSubmenu() {
      if (!this.isCollapse) {
        this.submenuShowed = true;
      }
    },
    closeSubmenu() {
      if (!this.isCollapse) {
        this.submenuShowed = false;
      }
    },
    highlineSubmenu(item) {
      if (!this.isCollapse) {
        item.isActive = true;
      }
    },
    unhighlineSubmenu(item) {
      if (!this.isCollapse) {
        item.isActive = false;
      }
    },
    highlineSubmenuItem(item) {
      if (!this.isCollapse) {
        item.isActive = true;
      }
    },
    unhighlineSubmenuItem(item) {
      if (!this.isCollapse) {
        item.isActive = false;
      }
    },
    goto(href) {
      this.menusShow = false;
      this.$router.push(href);
    },
    showMenus() {
      this.menusShow = !this.menusShow;
    },
    logout() {
      this.screenState.user = null;
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user_info");
      let currentPath = this.$route.path;
      let _this = this;
      setTimeout(function () {
        if (currentPath == _this.$route.path)
          window.location = window.location.href;
      }, 3000);
      message.showMessage(this, this.$router.lang.get("登出成功"), "success");
    },
    showDetail() {
      this.detailShow = true;
    },
    unshowDetail() {
      this.detailShow = false;
    },
  },
};
</script>

<style scoped>
.header {
  /* border-bottom: 1px solid #2b2a28; */
  /* padding-left: 3.33vw; */
  width: 100%;
  z-index: 999;
  background-color: rgba(16, 16, 15, 0.96);
  top: 0px;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
}

/* header下面有三个container分别是logo，menu和user */
/* 用flex去控制间隔 */

.fixed {
  position: fixed;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1vw;
}

.logo-container .logo {
  cursor: pointer;
  z-index: 99;
}

.menu-icon {
  height: 25px;
  width: 25px;
  margin-bottom: -7px;
  cursor: pointer;
}

.menu-container {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  opacity: 0.9;
  left: 0;
  /* left: min(683px, calc(192vw / 1920 * 683)); */
  @media (max-width: 1200px) {
    /* 在 1200px 以下的屏幕应用以下样式 */
    left: calc((-170 / 1200 * 100vw));
    /* 在这里添加你的 CSS 样式 */
  }
}

.menu-icon-container {
  display: inline-block;
  margin-top: 20px;
  padding-left: 4vw;
}

.menu-container > div:first-child {
  margin-left: 14vw;
  @media (min-width: 1200px) and (max-width: 1920px) {
    /* 1080p 修改边距*/
    /* margin-left: -8vw; */
  }
  @media (min-width: 1921px) and (max-width: 2560px) {
    /* 2k */
    /* margin-left: -5vw; */
  }
}

.mini > div:first-child {
  margin-left: calc(6.43vw + 73px);
}

.menu-container > div {
  max-width: 164px;
  width: 8.2vw;
  display: inline-block;
  text-align: center;
  padding: 24px 0;
  cursor: pointer;
  z-index: 9999;
}

.menu-container-mobile {
  position: absolute;
  width: 100vw;
  border-top: 1px solid #2b2a28;
  margin-top: 69px;
  background-color: #fff;
  color: #10100f;
  font-size: 18px;
  z-index: 999999999;
}

.menu-container-mobile > div {
  padding: 10px 14vw;
  border-bottom: 1px solid #ffdfa0;
}

.menu-container-mobile .menu-items {
  padding: 6px 0 0 4vw;
  font-size: 16px;
}

.user-container {
  display: flex;
  z-index: 999999;
  /* position: absolute; */
  /* top: 0; */
  /* right: 3.33vw; */
}

.user-container > div {
  display: inline-block;
  text-align: center;
}

.user-container > div:first-child > div {
  display: inline-block;
  line-height: 21px;
  max-width: 129px;
  width: 7.5vw;
  margin-top: 25px;
  overflow: hidden;
}

.user-container > div:first-child > div:first-child {
  border-right: 1px solid #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-icon {
  width: 20px;
  height: 20px;
  margin-bottom: -4px;
  margin-right: 0.52vw;
}

.icon-alone {
  margin-right: 4vw;
  cursor: pointer;
}

.download-container {
  width: 12vw;
  background-image: url("~@/assets/images/but_download.png");
  background-size: 100% 100%;
  height: 70px;
  text-align: center;
  font-size: 22px;

  cursor: pointer;
}

.download-container:hover {
  -moz-opacity: 0.9 !important;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
}

.download-container > div:first-child {
  padding-top: 9px;
}
.download-container > div:last-child {
  font-size: 12px;
}

.submenu-container {
  width: 100vw;
  height: 160px;
  background-color: #10100f;
  border-top: 1px solid #2b2a28;
  transition: height 1s;
  -moz-transition: height 1s;
  -webkit-transition: height 1s;
  -o-transition: height 1s;
}
.draw-enter-active,
.draw-leave-active {
  transition: all 1s ease;
  height: 0;
}
.draw-enter {
  height: 0;
}
.draw-enter-to {
  height: 160px;
}

/* .draw-enter-active,
.draw-leave-active {
  transition: all 2s ease;  
}
.draw-enter,
.draw-leave-to {
  height: 0;
} */

.menu-container .menu-items {
  position: absolute;
  max-width: 164px;
  width: 8.2vw;
  text-align: center;
  line-height: 36px;
  margin-top: 24px;
  /* padding: 4px; */
  padding-top: 24px;
}

.menu-items > div,
.menu-container-mobile .menu-title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.active-menu > .menu-items {
  border-top: 1px solid #c6a86c;
  margin-top: 23px;
  border-image: -webkit-linear-gradient(to left, #2b2a28, #c6a86c, #2b2a28) 8 10
    8;
  border-image: -moz-linear-gradient(to left, #2b2a28, #c6a86c, #2b2a28) 8 10 8;
  border-image: linear-gradient(to left, #2b2a28, #c6a86c, #2b2a28) 8 10 8;
}

.active-menu > .gradient-back {
  width: 84px;
  height: 15px;
  position: absolute;
  margin: 0 calc((8.9vw - 84px) / 2);
  margin-top: 25px;
  overflow: hidden;
}

.active-menu > .gradient-back-max {
  margin: 0 40px;
  margin-top: 25px;
}

.active-menu > .gradient-back > div {
  height: 40px;
  width: 84px;
  position: absolute;
  top: -20px;
  background-image: radial-gradient(
    closest-side at 50% 50%,
    #383122,
    #383122,
    #10100f,
    #10100f
  );
}

.active-menu-item {
  color: #c6a86c;
}

.user-detail-placeholder {
  position: fixed;
  width: 177px;
  height: 27px;
}

.user-detail {
  background: #101010;
  position: fixed;
  height: 205px;
  opacity: 0.96;
  border-top: 1px solid #2b2a28;
  border-image: -webkit-linear-gradient(to left, #2b2a28, #c6a86c, #2b2a28) 8 10
    8;
  border-image: -moz-linear-gradient(to left, #2b2a28, #c6a86c, #2b2a28) 8 10 8;
  border-image: linear-gradient(to left, #2b2a28, #c6a86c, #2b2a28) 8 10 8;
  margin-top: 27px;
  overflow: hidden;
  font-size: 14px;
  text-align: left;
}

.user-detail > div {
  padding-left: 20px;
}

.light {
  width: 177px;
  height: 14px;
  background-image: radial-gradient(
    closest-side at 50% 50%,
    #383122,
    #383122,
    #10100f,
    #10100f
  );
  margin-top: -7px;
}

.links-container {
  border-bottom: 1px solid #2b2a28;
}

.links-container > div {
  padding-bottom: 17px;
  cursor: pointer;
}

.points-container > div:nth-child(2) {
  font-size: 12px;
  opacity: 0.59;
  line-height: 15px;
}

.points-container > div:first-child {
  margin: 10px 0;
}

.points-container > div:last-child {
  margin: 10px 0;
  width: 68px;
  height: 21px;
  text-align: center;
  border: 1px solid #a3a3a3;
  cursor: pointer;
}

.points-container i {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  margin: 0 20px;
}

.cursor {
  cursor: pointer;
}
.cursor:hover {
  color: #c6a86c;
}
.user-detail-mobile {
  margin-top: 24px;
}
</style>
