<template>
    <div class="fullscreen" :class="isCollapse?'fullscreen-collapse':''">
        <div class="fullscreen-content">
            <div>
                <div>
                    <img class="pre-butt" @click="preMap" src="../assets/images/but_arrow_left.png"/>
                </div>
            </div>
            <div>
                <img class="fullscreen-map" :src="staticUrl + cover"/>
            </div>
            <div>
                <div class="close-butt-container">
                    <img class="close-butt" @click="closeFullScreen" src="../assets/images/but_guanbi.png"/>
                </div>
                <div>
                    <img class="next-butt" @click="nextMap" src="../assets/images/but_arrow_right.png"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useScreenState } from '../store';
import request from '../api/request'

export default {
    name: 'PangMapFull',
    props: {
        cover: {
            type: String,
            required: true
        },
    },
    data () {
        return {
            screenState: useScreenState(),
          staticUrl: request.staticUrl,
        };
    },
    methods: {
        preMap () {
            this.$emit('premap', {})
        },
        nextMap () {
            this.$emit('nextmap', {})
        },
        closeFullScreen () {
            this.$emit('close', {})
        },
    },
    computed: {
        isCollapse() {
        return this.screenState.screenWidth < 900;
        },
    },
}
</script>

<style scoped>

.fullscreen {
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.68);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.fullscreen-content {
    margin-top: 10vh;
    display: flex;
}

.fullscreen-content > div:first-child{
    flex: revert;
    margin: auto;
    margin-right: 6.25vw;
}

.fullscreen-content > div:last-child{
    flex: revert;
    margin: auto;
    margin-left: 1.41vw;
}

.pre-butt {
    max-width: 39px;
    max-height: 62px;
    width: 2.03vw;
    height: 3.23vw;
    cursor: pointer;
    border: none;
}

.next-butt {
    max-width: 39px;
    max-height: 62px;
    width: 2.03vw;
    height: 3.23vw;
    margin-left: 4.84vw;
    cursor: pointer;
    border: none;
}

.fullscreen-map {
    max-width: 956px;
    min-height: 20vw;
    width: 77.5vw;
}

.close-butt {
    max-width: 87px;
    max-height: 96px;
    width: 4.53vw;
    height: 5vw;
    cursor: pointer;
    border: none;
}

.close-butt-container {
    position: absolute;
    top: 10vh;
}

</style>
