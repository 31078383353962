<template>
  <div class="pang-pager" :class="isCollapse?'collapse':''">
      <div @click="goto(currentPage - 1)" class="page-butt">{{ $router.lang.get('上一頁') }}</div>
      <div class="paging-container">
          <div @click="goto(1)" v-if="hasPre">...</div>
          <div @click="goto(i)" v-for="i in pre" :key="i">{{ i }}</div>
          <div class="active">{{ currentPage }}</div>
          <div  @click="goto(i)" v-for="i in next" :key="i">{{ i }}</div>
          <div @click="goto(totalPages)" v-if="hasNext">...</div>
      </div>
      <div @click="goto(currentPage + 1)" class="page-butt">{{ $router.lang.get('下一頁') }}</div>
  </div>
</template>

<script>

import { useScreenState } from '../store';

export default {
  name: 'PangPager',
  props: {
    totalPages: {
      type: Number,
      default: 100,
    },
    currentPage: {
      type: Number,
      default: 97,
    },
  },
  data () {
      return {
            screenState: useScreenState(),
      }
  },
  methods: {
    goto (page) {
        if (page > 0 && page <= this.totalPages)
            this.$emit('change', page)
    },
  },
  computed: {
    isCollapse() {
        return this.screenState.screenWidth < 900;
    },
    hasPre () {
        return this.totalPages > 5 && this.currentPage > 3
    },
    hasNext () {
        return this.totalPages > 5 && this.currentPage <= this.totalPages - 3
    },
    pre () {
        if (this.currentPage >= this.totalPages - 1) {
            let delta = this.totalPages - this.currentPage
            let length = Math.min(4 - delta, this.currentPage - 1)
            return Array.from({length: length}, (v,k) => this.currentPage - length + k);
        }
        if (this.currentPage >=3)
        return [this.currentPage - 2, this.currentPage - 1]
        return Array.from({length: this.currentPage - 1}, (v,k) => k+1);
    },
    next () {
        if (this.currentPage < 3)
            return Array.from({length: Math.min(5 - this.currentPage, this.totalPages - this.currentPage)}, (v,k) => this.currentPage + k+1);
        if (this.currentPage <= this.totalPages - 2)
            return [this.currentPage + 1, this.currentPage + 2]
        return Array.from({length: this.totalPages - this.currentPage}, (v,k) => this.currentPage + k+1);
    },
  }
}
</script>

<style scoped>

.pang-pager {
    font-weight: 300;
    color: #ACACB0;
    display: flex;
    margin: auto;
    -webkit-user-select:none;
   -moz-user-select:none;
   -ms-user-select:none;
   user-select:none;
}
.pang-pager > div:first-child {
    margin-left: auto;
}
.pang-pager > div:last-child {
    margin-right: auto;
}

.paging-container {
    display: flex;
}

.page-butt, .paging-container > div {
    height: 36px;
    line-height: 36px;
    border: 1px solid #ACACB0;
    text-align: center;
    margin: 0 4.5px;
    padding: 0 5px;
    cursor: pointer;
}

.paging-container > div {
    width: 26px;
}

.active {
    background: #C6A86C;
    color: #FFFFFF;
}

.collapse {
    font-size: calc(1.5vw + 2.5px);
}

.collapse .page-butt {
    padding: 0 0.5vw;
    height: calc(2vw + 18px);
    line-height: calc(2vw + 18px);
}

.collapse .paging-container > div {
    width: calc(2vw + 8px);
    height: calc(2vw + 18px);
    line-height: calc(2vw + 18px);
}

</style>
