import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { screenState, createScreenState } from './store';

import 'font-awesome/scss/font-awesome.scss'// FontAwesome
import './assets/css/style.css'
import animated from 'animate.css'
const app = createApp(App)
app.use(router)

app.use(animated)

app.provide(screenState, createScreenState());

app.mount('#app')
