<template>
  <div :class="isCollapse ? 'pang-sms-input-mobile' : 'pang-sms-input'">
    <div
      :class="
        isCollapse ? 'phone-input-container-mobile' : 'phone-input-container'
      "
    >
      <div class="phone-container" :class="noAreaCode ? 'no-area-code' : ''">
        <select
          v-if="!noAreaCode"
          :disabled="inputDisabled"
          :value="areacode"
          @input="updateAreacodeAndVerify($event.target.value)"
        >
          <option value="886">TW+886</option>
          <option value="852">HK+852</option>
          <option value="853">MC+853</option>
        </select>
        <div class="input-but-container" :class="isFocus ? 'focus' : ''">
          <input
            :placeholder="
              !noAreaCode && areacode == '886' ? '9XXXXXXXX' : placeholder
            "
            :disabled="inputDisabled"
            ref="phone"
            @focus="phoneFocus()"
            @blur="onBlur"
            @input="updatePhone($event.target.value)"
            @keyup.enter="getCode"
            :value="phone"
            :name="name"
          />
          <button @click="getCode" :disabled="screenState[buttMsgField] || smsDisabled">
            {{
              screenState[buttMsgField]
                ? screenState[buttMsgField]
                : $router.lang.get(isCollapse?'驗證碼':"獲取驗證碼")
            }}
          </button>
        </div>
      </div>
      <div class="error-message">{{ message }}</div>
    </div>
    <div :class="isCollapse ? 'code-container-mobile' : 'code-container'">
      <pang-input
        :placeholder="$router.lang.get('輸入驗證碼')"
        ref="code"
        required
        v-model:value="code2"
        name="code"
        @input="changeCode"
        :validate="codeValidator"
        @keyup.enter="onEnter"
      />
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

import PangInput from "./PangInput";
import { useScreenState } from "../store";
import request from "../api/request";
import validate from "../utils/validate";
import message from "../utils/message";
import lang from "../lang";

export default {
  name: "PangSmsInput",
  components: {
    PangInput,
  },
  props: {
    areacode: String,
    noAreaCode: {
      type: Boolean,
      default: false,
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: lang.get("XXXXXXXX"),
    },
    phone: {
      type: String,
      default: "",
    },
    smsDisabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "phone",
    },
    required: {
      type: Boolean,
      default: true,
    },
    code: {
      type: String,
      default: "",
    },
    verifySource: {
      type: Number,
      default: 1,
    },
    verifyType: {
      type: Number,
      default: 1,
    },
    buttMsgField: {
      type: String,
      default: "buttMsg",
    },
    buttMsgCountingField: {
      type: String,
      default: "buttMsgCounting",
    },
    validate: {
      type: Function,
      default: function (value, _this) {
        if (_this.required && !value) return validate.phone(value);
      },
    },

  },
  data() {
    return {
      screenState: useScreenState(),
      phone2: this.phone,
      code2: this.code,
      message: "",
      isFocus: false,
      codeValidator: validate.code,
    };
  },
  setup(props, { emit }) {
    const updateAreacode = (areacode) => {
      emit("update:areacode", areacode);
    };
    const updatePhone = (phone) => {
      emit("update:phone", phone);
    };
    const updateCode = (code) => {
      emit("update:code", code);
    };
    return { updateAreacode, updatePhone, updateCode };
  },
  watch: {
    code2(val) {
      this.updateCode(val);
    },
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
  },
  methods: {
    onBlur(event) {
      let value = event.target.value
      if (this.type != 'password') {
        let trimed = value.trim()
        if (trimed !== value) {
          value = trimed
          this.updatePhone(value)
        }
      }
      this.validateValue(value)
    },
    changeCode(code) {
      this.updateCode(code);
    },
    async updateAreacodeAndVerify(areacode) {
      this.updateAreacode(areacode);
      await this.validatePhone(this.phone, areacode);
    },
    updateMsg(message) {
      if (message) {
        this.message = message;
        return false;
      }
      this.message = "";
      return true;
    },
    async validatePhone(phone, areacode = null) {
      if (!areacode) areacode = this.areacode;
      if (!this.noAreaCode) phone = areacode + phone;
      this.isFocus = false;
      let message = this.validate(phone, this);
      if (message && message.then) {
        message = await message;
      }
      return this.updateMsg(message);
    },
    async validateValue() {
      let v1 = await this.validatePhone(this.phone);
      let v2 = await this.$refs.code.validateValue(this.code2);
      if (v1 && v2) {
        return true;
      }
      return false;
    },
    phoneFocus() {
      this.isFocus = true;
    },
    decreaseSecond() {
      if (this.screenState[this.buttMsgField] > 0) {
        this.screenState[this.buttMsgField]--;
        setTimeout(this.decreaseSecond, 1000);
      } else this.screenState[this.buttMsgCountingField] = false;
    },
    async getCode() {
      if (!this.screenState[this.buttMsgField] && (await this.validatePhone(this.phone))) {
        let _this = this;
        let data = {
          verify_source: this.verifySource,
          verify_type: this.verifyType,
        };
        data[this.name] = this.phone;
        if (!this.noAreaCode) data.areacode = this.areacode;
        request.post("/verify/send", data, this).then(function (res) {
          if (res.status == 200) {
            _this.$refs.code.focus();
            message.showMessage(_this, lang.get("發送成功"), "success");
          } else {
            message.resMessage(res, _this);
          }
        });
        this.screenState[this.buttMsgField] = 60;
        Cookies.set("buttMsg", new Date().getTime(), {
          expires: new Date(new Date().getTime() + 60000),
        });
        setTimeout(this.decreaseSecond, 1000);
        this.screenState[this.buttMsgCountingField] = true;
      } else this.$refs.code.focus();
    },
    onEnter() {
      this.$emit("enter", { value: this.value });
    },
    focus() {
      this.$refs.phone.focus();
    },
  },
  mounted() {
    if (this.screenState[this.buttMsgField] > 0 && !this.screenState[this.buttMsgCountingField]) {
      setTimeout(this.decreaseSecond, 1000);
      this.screenState[this.buttMsgCountingField] = true;
    }
  },
};
</script>

<style scoped>
.pang-sms-input {
  display: flex;
}

select {
  font-size: 16px;
  font-weight: 300;
  color: #999999;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  background-color: #fff;
  width: 25.14%;
  padding: 0 10px;
}

select:focus {
  outline: none !important;
  border: 1px solid #c7c7c7;
}

input {
  height: 44px;
  width: calc(100% - 156px);
  padding: 0 15px;
  border: none;
  font-size: 16px;
}

input:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.focus {
  outline: none !important;
  border: 1px solid #c26161;
  box-shadow: 0 0 5px #c7c7c7;
}

input::placeholder {
  font-weight: 300;
  color: #999999;
}

.error-message {
  color: #c26161;
  margin: 8px 4px 0 4px;
  height: 24px;
}

.phone-container {
  display: flex;
}

.input-but-container {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  margin-left: 17px;
  overflow: hidden;
  width: calc(74.86% - 17px);
}

.input-but-container button {
  height: 30px;
  background: #c6a86c;
  border-radius: 15px;
  font-size: 14px;
  color: #ffffff;
  width: 120px;
}

.input-but-container button:disabled {
  cursor: unset;
  background: #c7c7c7;
}

.phone-input-container {
  width: 70.78%;
}

.phone-input-container-mobile {
  width: 100%;
  margin-bottom: 21px;
}

.code-container {
  margin-left: 17px;
}

.no-area-code .input-but-container {
  margin-left: 0;
  width: calc(100% - 7px);
}

.pang-sms-input-mobile input, .pang-sms-input-mobile select {
    font-size: calc(1vw + 7px);
}

.pang-sms-input-mobile input {
    width: calc(100% - 36px - 4vw - 44px);
    padding: 0 15px;
}

.pang-sms-input-mobile select {
    width: calc(25.14% + 19px - 2vw);
}

.pang-sms-input-mobile .input-but-container button {
    height: 30px;
    border-radius: 15px;
    font-size: calc(1vw + 5px);
    width: calc(4vw + 44px);
}

.pang-sms-input-mobile .input-but-container {
  margin-left: calc(2vw - 1px);
}
</style>
