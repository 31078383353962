<template>
  <div class="connect_us" @click="handleClick">
    <img src="@/assets/images/connct_us.png" alt="" />
  </div>
</template>
<script>
export default {
  methods: {
    handleClick() {
      this.$router.push("/customerservice/question");
    },
  },
};
</script>
<style lang="scss" scoped>
.connect_us {
  position: fixed;
  right: 0;
  bottom: 5%;
  z-index: 99;
  cursor: pointer;
}
</style>
