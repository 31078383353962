import { reactive, inject } from "vue";

import { constantRouterMap } from "../router";

import Cookies from "js-cookie";

const token = window.localStorage.getItem("token");
const userInfoStr = window.localStorage.getItem("user_info");
let user = null;
if (userInfoStr) {
  user = JSON.parse(userInfoStr);
  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_API.visitor = {
    name: user.username,
    email: user.email,
  };
  if (user.vip_level) {
    window.Tawk_API.onLoad = function() {
      window.Tawk_API.addTags([user.vip_level], function(error) {
        console.log(error);
      });
    };
  }
}

function constructMenus(pre, cur) {
  if (!cur.hidden) {
    pre.push({
      text: cur.meta ? cur.meta.title : "",
      href: cur.path,
      isActive: false,
      submenus: cur.children ? cur.children.reduce(constructMenus, []) : null,
    });
  }
  return pre;
}

function constructBreadcrumbs(pre, cur) {
  if (!pre.res[cur.path] && cur.meta && cur.meta.title) {
    pre.res[cur.path] = pre.parent.map((x) => {
      return x;
    });
    pre.res[cur.path].push({ text: cur.meta.title, href: cur.path });
    if (cur.children) {
      cur.children.reduce(constructBreadcrumbs, {
        parent: pre.res[cur.path],
        res: pre.res,
      });
    }
  }
  return pre;
}

let breadcrumbs = constantRouterMap.reduce(constructBreadcrumbs, {
  parent: [],
  res: {},
}).res;

let buttMsg = Cookies.get("buttMsg");
if (buttMsg) {
  buttMsg = 60 - parseInt((new Date().getTime() - parseInt(buttMsg)) / 1000);
  if (buttMsg < 0) buttMsg = 0;
}

export const screenState = Symbol();
export const createScreenState = () =>
  reactive({
    screenWidth: document.body.clientWidth,
    menus: constantRouterMap.reduce(constructMenus, []),
    breadcrumbs: breadcrumbs,
    token: token,
    user: user,
    msgs: [],
    messageList:[],
    buttMsg: buttMsg,
    buttMsgCounting: false,
  });
export const useScreenState = () => inject(screenState);
export default {
  screenState,
  createScreenState,
  useScreenState,
};
