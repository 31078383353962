<template>
    <div v-if="!isCollapse" class="breadcrumb">
        <div v-if="paths && paths.length > 0">
            <span class="link" @click="goto('/home')">{{ $router.lang.get('官網首頁') }}</span>
            <span v-for="path in paths"  :key="path.href">
                <span class="spliter">&gt;</span>
                <span class="link" @click="goto(path.href)">{{ path.text }}</span>
            </span>
        </div>
    </div>
</template>

<script>

import { useScreenState } from '../../../store';

const detailUrlRegex = /^(\/\w+)+(\/\d+)$/

export default {
  name: "Breadcrumb",
  data() {
    return {
      screenState: useScreenState(),
    }
  },
  computed: {
    isCollapse() {
      return this.screenState.screenWidth < 900;
    },
    paths () {
        let path = this.$route.path
        if (path == '/home' || path == '/404')
            return []
        if (detailUrlRegex.test(path))
          path = path.replace(/\/\d+$/, '')
        return this.screenState.breadcrumbs[path]
    }
  },
  methods: {
      goto (href) {
        this.$router.push(href)
      },
  }
};
</script>

<style scoped>
    .breadcrumb {
        margin: 70px auto 0 auto;
        padding: 0 5vw;
        max-width: 1200px;
        width: 90vw;
        text-align: right;
        color: #C6A86C;
    }

    .breadcrumb > div {
        padding-top: 38px;
    }

    .link {
        cursor: pointer;
    }

    .spliter {
        margin: 0 6px;
    }
</style>