<template>
    <div class="pang-table">
        <div class="theads">
            <div class="th" v-for="th in ths" :key="th" :style="{width: th.width}">{{ th.label }}</div>
        </div>
        <div class="tbody">
            <div class="tr" :class="$attrs.onRowclick?'clickable':''" v-for="row in rows" :key="row" @click="rowClick(row)">
                <div class="td" v-for="th in ths" :key="th" :style="{width: th.width}">{{ row[th.field] }}</div>
            </div>
            <div class="no-data" v-if="rows.length == 0">{{ $router.lang.get('暫無數據') }}</div>
        </div>
    </div>
</template>

<script>


import { useScreenState } from '../store';

export default {
    name: 'PangTable',
    props: {
        ths: Array,
        rows: Array,
    },
    data () {
        return {
            screenState: useScreenState(),
        }
    },
    computed: {
        isCollapse() {
        return this.screenState.screenWidth < 900;
        },
    },
    methods: {
        rowClick (row) {
            this.$emit('rowclick', row)
        },
    },
}
</script>

<style scoped>

.theads {
    display: flex;
    line-height: 48px;
    background: #E7EAF0;
}

.tr {
    display: flex;
    line-height: 69px;
    border-bottom: 1px solid #E7EAF0;
}

.tr:last-child {
    border-bottom: none;
}

.td {
    padding-left: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.th {
    padding-left: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.no-data {
    font-size: 40px;
    text-align: center;
    margin-top: 150px;
    color: #A8AEB3;
}

.clickable {
    cursor: pointer;
}

</style>
