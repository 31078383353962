<template>
  <div class="pang-message">
    <div
      v-for="(item, index) in list"
      :key="index"
      :class="[item.type, 'show-anim']"
    >
      {{ item.message }}
    </div>
  </div>
</template>

<script>
import { messageList } from "../globalMessage/index";
export default {
  name: "globalMessage",
  data() {
    return {
      list: messageList,
    };
  },
  mounted() {
    console.log(this.list);
  },
};
</script>

<style scoped>
.pang-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
}

.pang-message > div {
  border-radius: 4px;
  min-width: 150px;
  max-width: 80vw;
  line-height: 45px;
  padding: 0 40px;
  margin: 20px auto 0 auto;
  overflow: hidden;
  text-align: center;
}

.success {
  color: #67c23a;
  background-color: #f0f9eb;
  border: 1px solid #e1f3d8;
}

.error {
  color: #f56c6c;
  background-color: #fef0f0;
  border: 1px solid #fde2e2;
}

@-webkit-keyframes anim1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes anim2 {
  0% {
    opacity: 1;
    margin-top: 20px;
  }
  50% {
    opacity: 0;
    margin-top: -45px;
  }
  100% {
    opacity: 0;
    margin-top: -45px;
  }
}

.show-anim {
  animation: anim1 0.5s 1;
  -webkit-animation: anim1 0.5s 1;
}

.hide-anim {
  animation: anim2 1s 1;
  -webkit-animation: anim2 1s 1;
}
</style>
