<template>
    <div class="pang-product">
        <div class="detail">
            <div>
                <img :src="image">
            </div>
            <div class="title">{{ title }}</div>
        </div>
        <div class="border"></div>
        <div class="buy">
            <div class="price">{{ price }}</div>
            <div class="buy-butt">
                <pang-butt :loading="loading" :text="$router.lang.get('購買')" @click="buy" btype="submit" :style="{fontSize: '16px'}"/>
            </div>
        </div>
    </div>
</template>

<script>

import PangButt from './PangButt'

export default {
  name: 'PangProduct',
  components: {
    PangButt,
  },
  props: {
      image: String,
      title: String,
      price: String,
        loading: {
            type: Boolean,
            default: false,
        },
  },
  methods: {
      buy (e) {
        this.$emit('buy', e)
      },
  }
}
</script>

<style scoped>

.pang-product {
    width: calc(100% - 28px);
    text-align: center;
    margin: auto;
}

.detail {
    width: 100%;
    background: radial-gradient(circle at bottom left, transparent 15px, #fff 0) left,
      radial-gradient(circle at bottom right, transparent 15px, #fff 0) right;
    background-size: 50% 100%;
    background-repeat: no-repeat;
}

.detail img {
    width: 87px;
    height: 87px;
    margin: 24px;

}

.title {
    font-size: 28px;
    color: #C6A86C;
    padding-bottom: 20px;
}

.border {
    border-bottom: 1px dashed #fff;
    width: calc(100% - 30px);
    margin: auto;
}

.buy {
    width: 100%;
    background: radial-gradient(circle at top left, transparent 15px, #fff 0) left,
      radial-gradient(circle at top right, transparent 15px, #fff 0) right;
    background-size: 50% 100%;
    background-repeat: no-repeat;
}

.price {
    font-size: 18px;
    color: #616161;
    line-height: 32px;
}

.buy-butt {
    padding-bottom: 16px;
    padding-top: 4px;
}

.buy-butt div{
    line-height: 40px;
    width: 75%;
    background: #C6A86C;
    border-radius: 4px;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
    cursor: pointer;
}

</style>
