<template>
    <div class="pang-editor">
        <div class="editor">
            <div class="toolbar">
                <a @click="execute('undo')"><i class='fa fa-undo'></i></a>
                <a @click="execute('redo')"><i class='fa fa-repeat'></i></a>
                <div class="fore-wrapper" style="padding-bottom: 4px;" @click="changeFontSize(fontSize)">
                    <i class='fa fa-font' :style='{color: color}'><sub style="font-size: 11px">{{ fontSize }}</sub></i>
                    <div class="fore-palette">
                        <a
                        v-for="item in fontSizes" :key="item"
                        @click.stop="changeFontSize(item)"
                        class="palette-item">{{ item }}</a>
                    </div>
                </div>
                <div class="fore-wrapper" @click="forecolor(color)">
                    <i class='fa fa-font' :style='{color: color}'></i>
                    <div class="fore-palette">
                        <a
                        v-for="item in colors" :key="item"
                        @click.stop="forecolor(item)"
                        :style='{backgroundColor: item}'
                        class="palette-item"></a>
                    </div>
                </div>
                <div class="back-wrapper" @click.stop="backcolor(backgroundColor)">
                    <i class='fa fa-font' :style='{backgroundColor: backgroundColor}'></i>
                    <div class="back-palette">
                        <a
                        v-for="item in colors" :key="item"
                        @click.stop="backcolor(item)"
                        :style='{backgroundColor: item}'
                        class="palette-item"></a>
                    </div>
                </div>
                <a @click="execute('bold')"><i class='fa fa-bold'></i></a>
                <a @click="execute('italic')"><i class='fa fa-italic'></i></a>
                <a @click="execute('underline')"><i class='fa fa-underline'></i></a>
                <a @click="execute('strikeThrough')"><i class='fa fa-strikethrough'></i></a>
                <a @click="execute('justifyLeft')"><i class='fa fa-align-left'></i></a>
                <a @click="execute('justifyCenter')"><i class='fa fa-align-center'></i></a>
                <a @click="execute('justifyRight')"><i class='fa fa-align-right'></i></a>
                <a @click="execute('indent')"><i class='fa fa-indent'></i></a>
                <a @click="execute('outdent')"><i class='fa fa-outdent'></i></a>
                <a @click="formatBlock('h1')">H1</a>
                <a @click="formatBlock('h2')">H2</a>
                <a @click="inputLink()"><i class='fa fa-link'></i></a>
                <a @click="execute('unlink')"><i class='fa fa-unlink'></i></a>
                <a @click="inputImage()"><i class='fa fa-image'></i></a>
                <a @click="formatBlock('p')">P</a>
            </div>
            <div id='editor' ref='editor' contenteditable @input.stop="inputChange" @blur="textSelect" v-html="initValue"/>
            <pang-dialog v-if="inputItem" :title="inputItem.title" :cancelText="$router.lang.get('取消')" :submitText="$router.lang.get('確認')" @cancel="cancel" @submit="confirm">
                <div class="dialog-input">
                    <pang-input ref="urlInput" v-if="inputItem.command == 'createlink'" v-model:value="url" :placeholder="inputItem.title" required/>
                    <pang-upload v-else ref="urlInput" v-model:value="url" :placeholder="inputItem.title" required/>
                </div>
            </pang-dialog>
        </div>
        <div class="error-message">{{message}}</div>
     </div>
</template>

<script scope>

import PangDialog from './PangDialog'
import PangInput from './PangInput'
import PangUpload from './PangUpload'

export default {
    name: 'PangEditor',
    props: {
        value: { type: String, default: '' },
        required: { type: Boolean, default: false },
        minlength: { type: Number, default: 0 },
        maxlength: { type: Number, default: 0 },
        validate: {
            type: Function,
            default: function (value, _this) {
                if (_this.required && !value)
                    return _this.$router.lang.get('請輸入')
                if (_this.minlength && value.length < _this.minlength)
                    return _this.$router.lang.get('字數不足') + _this.minlength
                if (_this.maxlength && value.length > _this.maxlength)
                    return _this.$router.lang.get('字數超過') + _this.maxlength
            }
        },
    },
    components: {
        PangDialog,
        PangInput,
        PangUpload,
    },
    data () {
        return {
            range: null,
            inputItem: null,
            initValue: this.value,
            url: '',
            color: '#000000',
            backgroundColor: '#6699FF',
            message: '',
            fontSize: 16,
            colors: [
                '#000000',
                '#FF9966',
                '#6699FF',
                '#99FF66',
                '#CC0000',
                '#00CC00',
                '#0000CC',
                '#333333',
                '#0066FF',
                '#FFFFFF',
            ],
            fontSizes: [
                9,
                12,
                14,
                16,
                20,
                24,
                28,
                32,
                36,
                40,
            ],
        }
    },
    methods: {
        changeInitValue (content) {
            this.initValue = content
        },
        regainFocus () {
            this.$refs.editor.focus()
            if (this.range) {
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(this.range)
            }
        },
        execute(command) {
            this.regainFocus()
            document.execCommand(command, false, null)
        },
        formatBlock(command) {
            this.regainFocus()
            document.execCommand('formatBlock', false, command)
        },
        insertImgOrLink (command, url) {
            this.regainFocus()
            document.execCommand(command, false, url);
        },
        inputChange (e) {
            
            this.$emit('input', e.target.innerHTML)
        },
        textSelect () {
            this.range = window.getSelection().getRangeAt(0)
        },
        changeColor (command, color) {
            this.regainFocus()
            document.execCommand(command, false, color);
        },
        forecolor (color) {
            this.color = color
            this.changeColor('forecolor', color)
        },
        changeFontSize(fontSize) {
            this.fontSize = fontSize
            this.regainFocus()
            let html = '<font style="font-size: ' + fontSize +'px;">' + document.getSelection() + '</font>'
            document.execCommand('insertHTML', false, html)
        },
        backcolor (color) {
            this.backgroundColor = color
            this.changeColor('backcolor', color)
        },
        inputLink () {
            this.inputItem = {
                title: this.$router.lang.get('輸入鏈接'),
                command: 'createlink',
            }
        },
        inputImage () {
            this.inputItem = {
                title: this.$router.lang.get('上載圖像'),
                command: 'insertimage',
            }
        },
        cancel () {
            this.inputItem = null
        },
        async confirm () {
            if (await this.$refs.urlInput.validateValue(this.url)) {
                this.insertImgOrLink(this.inputItem.command, this.url)
                this.inputItem = null
            }
        },
        updateMsg (message) {
            if (message) {
                this.message = message
                return false
            }
            this.message = ''
            return true
        },
        async validateValue (value) {
            let message = this.validate(value, this)
            if (message && message.then) {
                message = await message
            }
            return this.updateMsg(message)
        },
    },
}
</script>

<style scoped>
a {
    cursor: pointer;
}

.editor {
    color: black;
    border: 1px solid #676A6D;
}

.toolboxes > i, .toolboxes > span {
    padding: 5px;
    margin: 3px;
}

[contenteditable]:focus{outline: none;}

.textarea {
    min-height: 300px;
}


#editor {
  box-shadow: 0 0 2px #CCC;
  min-height: 150px;
  overflow: auto;
  padding: 1em;
  margin-top: 20px;
  resize: vertical;
  outline: none;
}

.toolbar  {
    padding-left: 10px;
}

.toolbar a,
.fore-wrapper,
.back-wrapper {
  border: 1px solid #AAA;
  background: #FFF;
  font-family: 'Candal';
  border-radius: 1px;
  color: black;
  padding: 5px;
  width: 1.5em;
  margin: -2px;
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;
  box-shadow: 0px 1px 0px #CCC;
}

.toolbar a:hover,
.fore-wrapper:hover,
.back-wrapper:hover {
  background: #f2f2f2;
  border-color: #8c8c8c;
}

a[data-command='redo'],
a[data-command='strikeThrough'],
a[data-command='justifyFull'],
a[data-command='insertOrderedList'],
a[data-command='outdent'],
a[data-command='p'],
a[data-command='superscript'] {
  margin-right: 5px;
  border-radius: 0 3px 3px 0;
}

a[data-command='undo'],
.fore-wrapper,
a[data-command='justifyLeft'],
a[data-command='insertUnorderedList'],
a[data-command='indent'],
a[data-command='h1'],
a[data-command='subscript'] {
  border-radius: 3px 0 0 3px;
}

a.palette-item {
  line-height: 1em;
  text-align: center;
  height: 1em;
  border-radius: 3px;
  margin: 2px;
  width: 1em;
  border: 1px solid #CCC;
}

a.palette-item:hover {
  border: 1px solid #CCC;
  box-shadow: 0 0 3px #333;
}

.fore-palette,
.back-palette {
  display: none;
}

.fore-wrapper,
.back-wrapper {
  display: inline-block;
  cursor: pointer;
}

.fore-wrapper:hover .fore-palette,
.back-wrapper:hover .back-palette {
  display: block;
  float: left;
  position: absolute;
  padding: 3px;
  width: 160px;
  background: #FFF;
  border: 1px solid #DDD;
  box-shadow: 0 0 5px #CCC;
  height: 70px;
}

.fore-palette a,
.back-palette a {
  background: #FFF;
  margin-bottom: 2px;
}

.dialog-input {
    padding-top: 65px;
}

.error-message {
color: #C26161;
margin: 8px 4px 0 4px;
height: 24px;
}
</style>